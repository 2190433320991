$color-white: #fff;
$color-black: #000000;
$color-black2: #070B1A;
$color-black3: #333333;
$color-black-40: rgba(0,0,0,0.4);
$color-black-50: rgba(0,0,0,0.5);
$color-black-60: rgba(0,0,0,0.6);
$color-black-70: rgba(0,0,0,0.7);
$color-black-80: rgba(0,0,0,0.8);
$color-black-30: rgba(0,0,0,0.3);
$color-black-20: rgba(0,0,0,0.2);
$color-black-10: rgba(0,0,0,0.1);
$color-lightgrey1: #F5F5F5;
$color-lightgrey2: #CBCBCB;
$color-lightgrey3: #E3E3E3;
$color-lightgrey4: #D1D1D1;
$color-lightgrey5: #BEBEBE;
$color-lightgrey6: #ADADAD;
$color-lightgrey7: #7F7F7F;
$color-lightgrey8: rgba(0,0,0,0.8);
$color-lightgrey9: #5C5C5C;
$color-lightgrey10: #656565;
$color-lightgrey11: #fafafa;
$color-lightgrey12: #f5f5f5;
$color-lightgrey13: #f4f7ff;
$color-lightgrey14: #757575;
$color-lightgrey15: #fdfdfd;
$color-lightgrey16: #eaeaea;
$color-lightgrey17: #f0f0f0;
$color-lightgrey18: #fcfcfc;
$color-lightgrey19: #ededed;
$color-lightgrey20: #f7f7f7;
$color-lightgrey21: #E8E8E8;
$color-lightgrey22: #4A4A4A;
$color-lightgrey23: #7B7B7B;
$color-lightgrey24: #F2F2F2;
$color-lightgrey25: #7d7d7d;
$color-lightgrey26: #F8F8F8;
$color-lightgrey27: #A5A5A5;
$color-lightgrey28: #b5b5b5;
$color-lightgrey29: #dedede;
$color-lightgrey30: #D3D3D3;
$color-lightgrey31: #e5e5e5;
$color-lightgrey32: #1a1a1a;
$color-lightgrey33: #E0E0E0;
$color-lightgrey34: #eff1f6;
$color-lightgrey35: #8b8b8b;
$color-lightgrey36: #d8d8d8;
$color-lightgrey37: #EFEFEF;
$color-lightgrey38: #e0ddd5;
$color-lightgrey39: #e3e8f7;
$color-lightgrey40: #f4f4f4;
$color-darkgrey1: #434F6A;
$color-blue1: #0377FF;
$color-blue1-05: rgba(45,104,248, 0.05);
$color-blue1-10: rgba(45,104,248, 0.10);
$color-blue1-20: rgba(45,104,248, 0.20);
$color-blue2: #0377FF;
$color-blue3: #10234C;
$color-blue4: #0377FF;
$color-blue5: #f5f9ff;
$color-blue6: #F4F7FF;
$color-blue7: #0B224F;
$color-blue8: #476096;
$color-blue8-70: rgba(71,96,150,0.7);
$color-blue9: #B1D8FF;
$color-blue10: #E6F2FF;
$color-hover1: #ffecbf;
$color-red: #E55252;
$color-red2: #FFE3E3;
$color-red3: #E55F5F;
$color-red4: #EA7A7A;
$color-red5: #E23D3D;
$color-red-10: rgba(244,67,54,0.1);
$color-orange: #FAB446;
$color-green: #73AF00;
$color-green2: #42C951;
$color-green3: #D8FFD3;
$color-green4: #257C6F;
$color-green5: #5AA28C;
$color-green6: #32AA75;
$color-yellow1: #FFF1B5;
$color-yellow2: #A37B34;
$color-yellow3: #B5924D;
$color-url-bg: #17304d;
$color-video-bg: #F5C629;
$color-word-doc-bg: #F4F7FF;
$color-pdf-bg: #ff4a4a;
$color-audio-bg: #ff3e97;
$color-image-icon-bg: #0377ff;
$color-ppt-bg: #ff4a4a;


// font sizes
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-17: 17px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-30: 30px;
$font-34: 34px;
$font-36: 36px;
$font-38: 38px;
$font-40: 40px;
$font-42: 42px;
$font-44: 44px;
$font-48: 48px;
$font-52: 52px;

    $f-22: 1.1vw;
    $f-24: 1.2vw; 
    $f-26: 1.3px;
    $f-28: 1.4vw;
    $f-30: 1.5vw;
    $f-34: 1.7vw;
    $f-36: 1.8vw;
    $f-38: 1.9vw;
    $f-40: 2vw;
    $f-42: 2.1vw;
    $f-44: 2.2vw;
    $f-48: 2.4vw;
    $f-52: 2.6vw;
    
