.green_theme {
    $light_primary_color: #000;
    $opacity_color: #F4F7FF;

$theme_color:#0377FF;
    & button {


        &.btn-submit {
            background-color: $light_primary_color;
            color: $color-white;

            border: 1px solid $light_primary_color;



            &:focus {
                border: 1px solid $light_primary_color;
                color: $color-white;
            }



            &.mat-button-disabled {
                background-color: $light_primary_color  !important;

                color: $color-white  !important;


            }

            &:active {
                background-color: $light_primary_color;
                color: $color-white;
            }
        }

        &.btn-upload {
            background-color: $color-white;
            color: $light_primary_color;

            border: 1px solid $light_primary_color;


        }

        &.btn-upload-transparent {
            background-color: transparent;
            color: $light_primary_color;

            border: 1px solid $light_primary_color;


        }

        &.btn-resend {
            background-color: $light_primary_color;
            border: 1px solid $light_primary_color;
            color: $light_primary_color;


            &.resend-confirm,
            &:focus {
                background-color: $light_primary_color;
                border: 1px solid $light_primary_color;
                color: $color-white;
            }
        }
    }

    & .btn-primary {

        background-color: $light_primary_color  !important;
        border-color: $light_primary_color  !important;

        &.mat-button-disabled {
            opacity: 0.3 !important;
            color: #fff !important;
        }
    }

    & .btn-primary:not(:disabled):not(.disabled).active,
    & .btn-primary:not(:disabled):not(.disabled):active,
    & .show>.btn-primary.dropdown-toggle {
        background-color: $light_primary_color;
        border-color: $light_primary_color;
        color: $color-white;
    }




    & .icon {


&.icon-invite{   background-image: url("../images/green_theme/invite.png");}

        &.icon-left-home-logo {
            background-image: url("../images/green_theme/home_normal.png");
        }

        &.icon-library-nav {
            background-image: url("../images/green_theme/library_normal.png");
        } 

        &.icon-lsp-nav {
            background-image: url("../images/green_theme/space_normal.png");
        }


        &.icon-lp {
            background-image: url("../images/green_theme/path_normal.png");
        }

        &.icon-settings {
            background-image: url("../images/green_theme/settings_normal.png");
        }

        &.icon-logo {
            background-image: url("../images/green_theme/logo.png");
        }
    }


    & .active {
        & .icon {
            &.icon.icon-lp {
                background-image: url("../images/green_theme/path_active.png");
            }

            &.icon-left-home-logo {
                background-image: url("../images/green_theme/home_active.png");
            }

            &.icon-library-nav {
                background-image: url("../images/green_theme/library_active.png");
            }

            &.icon-lsp-nav {
                background-image: url("../images/green_theme/space_active.png");
                cursor: auto !important;
            }

            &.icon-settings {
                background-image: url("../images/green_theme/settings_active.png");
            }

        }
    }

    & .icon {
        &.icon-library-blue {
            background-image: url("../images/green_theme/library_active.png");
        }
    }



    & .left-sidebar {
       
        background: #d4ff6a;
    background: transparent linear-gradient(236deg, #D4FF6A 0%, #00D0A4 100%) 0% 0% no-repeat padding-box;
    }

    & .views_desc {
        &.pathClr {
            color: #12C464 !important;
        }

        &.spaceClr {
            color: $light_primary_color  !important;
        }
    }


    .tabsWrap {
        & li {
            & a {
                border: 1px solid #000;
                color: #000;

                &:hover,
                &.active {
                    background: $light_primary_color;
                    color: #fff;
                    border-color: $light_primary_color;
                }
            }
        }
    }



    & .template-box {
        & .template-img {

            &:hover {
                border: 1px solid $light_primary_color  !important;
                background: $opacity_color  !important;
            }
        }

        &.active-template {
            & .p-small-black15 {
                color: $light_primary_color  !important;
            }

            & .template-img {
                border: 1px solid $light_primary_color  !important;
                background: $opacity_color  !important;
            }
        }

    }

    .mat-tab-group {
        .mat-tab-label {


            &.mat-tab-label-active {
                border-bottom: 2px solid $light_primary_color;

                .mat-tab-label-content {
                    color: $light_primary_color;
                }
            }
        }
    }



    & .profilename {
        & a {
            color: $light_primary_color;
        }
    }

    & .profile-navtabs .mat-tab-label {
        border: 1px solid $light_primary_color;
    }


    & .profile-navtabs .mat-tab-label-active {

        color: $color-white  !important;
        background-color: $light_primary_color;

        & .mat-tab-label-content {
            color: $color-white  !important;
        }
    }

    & .profile-details {
       

        & h3 {
            color: #fff;
        }

        & p {
            color: #fff;
        }
    }


    .learningspacebox {
        background: #19d0f8;
        background: linear-gradient(-154deg, #19d0f8 0.00%, #005dff 100.00%);

        & h4 {
            color: #fff;
        }

        & p {
            color: #fff;
        }
    }

    .learningpathbox {

        background: #d4ff6a;
      
        background: transparent linear-gradient(236deg, #D4FF6A 0%, #00D0A4 100%) 0% 0% no-repeat padding-box;
        

        & h4 {
            color: #fff;
        }

        & p {
            color: #fff;
        }
    }

    & .icon {
        &.icon_card_space {
            width: 33px;
            height: 33px;
            background-image: url("../images/green_theme/space_card.png");
            background-size: 33px 33px;
            display: inline-block;
        }

        &.icon_card_path {
            width: 33px;
            height: 33px;
            background-image: url("../images/green_theme/path_card.png");
            background-size: 33px 33px;
            display: inline-block;
        }
    }

    & .log-img span {
        background-image: url("../images/green_theme/logout.png");
    }
    .left-icons a:hover {
       background: rgb(0 0 0 / 10%);
       & i{ color: #000 !important;}
       & .icon{ opacity: 1 !important;}
   }
   .left-icons a i{ color: #000 !important;}
   .left-sidebar .left-icons .icon{ opacity: .4;}
   .left-sidebar .left-icons a.active .icon{ opacity: 1;}
  
   .opensidebar .profile-sec-inner {
       background-color: rgba(31, 31, 31, .1);
  
   }

.invite_wrap_inner{ background: #1F1F1F;
& i{ color: #fff;}
& p{ color: #fff;}
}
& .opensidebar{
& .icon.icon-invite{   background-image: url("../images/green_theme/invite_active_open.png");}

}
.left-icons .active i {
 color: #fff;
}
.home-icon a{ text-decoration: none;}

& .home-icon i{ color: #000; font-size:22px ; font-weight: 700;}

& .logo-section i{ font-size:18px !important; color: #000;}
& .custom-btn{ background: #000; color: #fff;}
& .invitelink{ color: #36FF7C;}
& .logout i{ color: #000;}

& .profile-sec-inner p{ color: #000;}
.mat-progress-bar-fill::after {
   background-color: $light_primary_color;
}
.header-main {
& .notificationIcon{ & .mat-icon{ color: #000; }
&:hover{& .mat-icon{ color: $light_primary_color;}}
}
& .faqIcon { & .mat-icon{ color: #000;}
&:hover{& .mat-icon{ color: $light_primary_color;}}
}
}
app-library-info 
{ 


& .mat-tab-label.mat-tab-label-active {  background:$light_primary_color;
    
    & .mat-tab-label-content{
    color:#fff !important;
    & .mat-button-wrapper{ color: #fff !important;}
}
}
}

.uploadBtn {
    background-color: $light_primary_color !important;
}

&  app-questionnaire-create{
    & .mat-tab-label  button{ background: #ebe9e9; color: #000 !important;}
    & .mat-tab-label-active button{  background-color: $light_primary_color !important; color: #fff !important;
    & .mat-button-wrapper{ color: #fff !important;}
    }
        }
& body { background: #FFFCF5;}
& .mat-drawer-container, & .spaces-cards {
    background: #FFFCF5 !important;
}


.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $light_primary_color !important;
    color: #fff;
}

button.btn-submit.view_color{
    background: #f7f7f7;
    color: #000;
    border:0; font-size: 16px;
    & span{ font-weight: 600;}

 }
 
 @media screen and (max-width: 1023px)
 {
 .home-icon .icon.icon-logo{

    background-color: #d4ff6a
 }
}

   }

