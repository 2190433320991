@import "./assets/css/globalstyles";
@import "./assets/css/variables";

html {
  overflow: hidden;
}

.mat-radio-inner-circle {
  background-color: #4051b5 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4051b5 !important;
}
.mat-horizontal-stepper.mat-stepper-horizontal.stepc.ng-tns-c238-6.mat-stepper-label-position-end {
  background-color: #ccc3 !important;
}

div#cdk-step-content-0-0 {
  padding-top: 10px !important;
}

.mat-horizontal-stepper-header-container.ng-tns-c232-8 {
  height: 39px !important;
  padding-top: 28px !important;
  border-radius: 3px !important;
}
mat-horizontal-stepper.mat-stepper-horizontal.stepc.ng-tns-c232-8.mat-stepper-label-position-end {
  margin-top: 20px;
  border-radius: 10px;
}
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: #fff;
  box-shadow: 0px 6px 18px #00000012;
  margin-top: 30px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.fl-img img,
.fl-img .placeholder-name {
  width: 110px !important;
  height: 110px !important;
}
.fl-img h2 {
  font-size: 20px !important;
}
.manage-connections-box img,
.manage-connections-box .placeholder-name {
  width: 90px !important;
  height: 90px !important;
}
.manage-connections-box h2 {
  font-size: 20px !important;
}

x h2 {
  font-size: 20px !important;
}

.inputWrap,
.create_question {
  & input {
    padding: 6px 10px 4px 10px; font-size: 20px;
  }
  & .mat-form-field-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
  }
  & .mat-form-field-appearance-outline .mat-form-field-outline {
    display: none;
  }
  & .mat-form-field-label-wrapper {
    top: -20px;
  }
  & .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }
  & .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    display: none;
  }
  & ::-webkit-input-placeholder {
    /* Edge */
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 18px !important;
  }

  & :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 18px !important;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 18px !important;
  }
}
.actions {
  display: flex;
  align-items: center;
  padding-left: 10px;
  & .mat-checkbox-inner-container {
    width: 23px;
    height: 23px;
  }
}
.flexBlock {
  margin-bottom: 20px;
  height: 50px;
}

.noborder {
  & .mat-tab-header {
    position: sticky;
    top: 0px;
    z-index: 9;
    background: #fff;
  }
  & .mat-tab-labels {
    & .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-link-bar {
      background: none !important;
    }
    & button {
      min-width: 40px;
      line-height: 40px;
      font-size: 22px;
      border: 0px solid transparent;
    }
  }
}

.create_question {
  & ::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 20px !important;
  }
}

app-questionnaire-create .mat-tab-body-wrapper {
  height: calc(90vh - 254px);
  overflow: auto; padding-right: 10px;
  & .mat-tab-body-content{ padding-right: 30px;}
}

.questionlist .mat-pseudo-checkbox {
  opacity: 0 !important;
}

app-questionnaire-run {
  margin: 0 auto;
  max-width: 700px;
  display: block;
  background: white;
  padding: 20px 20px 20px 0;
}
.create_question .mat-form-field-infix{ border:0 !important}
.blur-effect {
  *:not(.signinwrapper) {
    filter: blur(3px);
  }
}

app-questionnaire-create{
.mat-form-field-appearance-outline .mat-form-field-flex{ margin-top: 0 !important;}
.mat-form-field-appearance-outline .mat-form-field-infix{ border-top: 0 !important;}
.inputWrap input{    padding: 6px 10px 4px 0px;
  font-size: 20px;
  margin-top: 0px;
  line-height: 20px;}
}