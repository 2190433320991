.icon {
    display: inline-block;
    background-size: cover;
&.icon_success{
    background-image: url("../images/icons/success_icon.png"); 
}
    &.icon-search {
        background-image: url("../images/icons/SVG/ic_search-active.svg");
    }

    &.icon-search-grey {
        background-image: url("../images/icons/ic_search.svg");
    }

    &.icon-search-blue {
        background-image: url("../images/icons/ic_search_blue.svg");
    }

    &.icon-search-blue1 {
        background-image: url("../images/icons/ic-search-blue1.svg");
    }

    &.icon-views {
        background-image: url("../images/icons/ic_views_new.png");
        height: 15px;
        width: 23px;
    }

    &.icon-comments {
        background-image: url("../images/icons/ic_comment_new.png");
        height: 19px;
        width: 19px;
        margin-top: 14px !important;
    }

    &.icon-visibility {
        background-image: url("../images/icons/ic_views_new.png");
        height: 19px;
        width: 19px;
    }

    &.icon-share-new {
        background-image: url("../images/icons/share_new.png");
        height: 21px;
        width: 19px;

    }

    .higlight-icon {
        background-image: url("../images/icons/share_new.png");

    }

    &.icon-edit {
        background-image: url("../images/icons/ic_edit_new.svg");
        height: 15px;
        width: 15px;

    }

    &.icon-library {
        background-image: url("../images/icons/ic_library_active.svg");
    }

    &.icon-share {
        background-image: url("../images/icons/ic_share.svg");
    }

    &.icon-rename {
        background-image: url("../images/icons/ic_rename.svg");
    }

    &.icon-delete {
        background-image: url("../images/icons/ic_delete.svg");
    }

    &.icon-discovery {
        background-image: url("../images/icons/Discovery-active.png");
    }

    &.icon-close {
        background-image: url("../images/icons/ic_close.svg");
    }

    &.icon-close-black {
        background-image: url("../images/icons/icon-close-black.svg");
    }

    &.ic-close-black2 {
        background-image: url("../images/icons/ic-close-black2.svg");
    }

    &.icon-ellipsis {
        background-image: url("../images/icons/ic_eliipses.svg");
    }

    &.icon-ellipsis-blue {
        background-image: url('../images/icons/ic-ellipsis-blue.svg');
    }

    &.icon-saved-library {
        background-image: url("../images/icons/ic_library_active.svg");
    }

    // &.icon-save-as {
    //     background-image: url("../images/icons/ic_Rename.svg");
    // }
    &.icon-options {
        background-image: url("../images/icons/ic-options.png");
    }

    &.icon-options-active {
        background-image: url("../images/icons/ic-options-active.png");
    }

    &.icon-delete-png {
        background-image: url("../images/icons/Delete.png");
    }

    &.icon-delete-active-png {
        background-image: url("../images/icons/Delete-active.png");
    }

    &.icon-plus-icon-active {
        background-image: url("../images/icons/ic-plus-icon-active.png");
    }

    &.icon-plus-blue {
        background-image: url("../images/icons/ic-plus-blue.png");
    }

    &.icon-back-white-arrow {
        background-image: url("../images/icons/ic-back-arrow-white.png");
    }

    &.icon-back-white-arrow-active {
        background-image: url("../images/icons/ic-back-arrow-white-active.png");
    }

    &.icon-forward-white-arrow {
        background-image: url("../images/icons/ic-forward-arrow-white.png");
    }

    &.icon-forward-arrow-white-active {
        background-image: url("../images/icons/ic-forward-arrow-white-active.png");
    }

    &.icon-hidden-password {
        background-image: url("../images/icons/ic-hidden-password.png");
    }

    &.icon-show-password {
        background-image: url("../images/icons/ic-show-password.png");
    }

    &.icon-info-grey {
        background-image: url("../images/icons/ic_info_grey.svg");
    }

    &.icon-info-dark {
        background-image: url("../images/icons/ic_info_dark.svg");
    }

    &.icon-plus-grey {
        background-image: url("../images/icons/ic_add.svg");
    }

    &.icon-add-grey {
        background-image: url('../images/icons/ic-add-grey.svg');
        width: 16px;
        height: 16px;
    }

    &.icon-add-blue {
        background-image: url('../images/icons/ic-add-blue.svg');
        width: 16px;
        height: 16px;
    }

    &.icon-message-grey {
        background-image: url("../images/icons/ic_messages.svg");
    }

    &.icon-notification-grey {
        background-image: url("../images/icons/ic_notifications.svg");
    }

    &.icon-discovery-blue {
        background-image: url("../images/icons/ic_discovery_active.svg");
    }

    &.icon-discovery-grey {
        background-image: url("../images/icons/ic_discover.svg");
    }

    &.icon-topics-blue {
        background-image: url("../images/icons/ic-topics-blue.png");
    }

    &.icon-topics-grey {
        background-image: url("../images/icons/ic-topics-grey.png");
    }

   

    &.icon-library-border-grey {
        background-image: url("../images/icons/ic_add_to_library.svg");
    }

 

   

    &.icon-lp-black {
        background-image: url("../images/icons/ic_learning_path_black.svg");
    }

    &.icon-lp-search {
        background-image: url("../images/icons/lp-icon-search.svg");

        &.draftIcon {
            background-image: url("../images/icons/lp-icon-search-draft.svg");
        }
    }

    &.icon-ls-search {
        background-image: url("../images/icons/ls-icon-search.svg");
    }

    &.icon-copy {
        background-image: url("../images/icons/ic_copy.svg");
    }

    &.icon-share-grey {
        background-image: url("../images/icons/ic_share.svg");
    }

    &.icon-exapnd-grey {
        background-image: url("../images/icons/ic_expand.svg");
    }

    &.icon-add-annotation-grey {
        background-image: url("../images/icons/ic_add_annotation.svg");
    }

    &.icon-add-tag-grey {
        background-image: url("../images/icons/ic_add_tag.svg");
    }

    &.icon-discovery-blue-circle-large {
        background-image: url("../images/icons/discovery-blue-large-circle.png");
    }

  

    &.icon-arrow-back-blue {
        background-image: url("../images/icons/ic-back-arrow-blue.png");
    }

    &.icon-upload-blue {
        background-image: url("../images/icons/ic_upload_active.svg");
    }

    &.icon-upload-grey {
        background-image: url("../images/icons/ic-upload-grey.svg");
    }

    &.icon-list-view-grey {
        background-image: url("../images/icons/list-view.png");
        width: 22px !important;
    height: 18px !important;
    }

    &.icon-grid-view-grey {
        background-image: url("../images/icons/grid-view.png");
    }

    &.icon-favourite-active {
        background-image: url("../images/icons/ic_star_blue.svg");
    }

    &.icon-favourite {
        background-image: url("../images/icons/ic_star_grey.svg");
    }

    &.icon-favourite-dark {
        background-image: url("../images/icons/ic_star_dark.svg");
    }

    &.icon-move-to-folder {
        background-image: url("../images/icons/ic_folder_filled.svg");
    }

    &.icon-folder-blue {
        background-image: url('../images/icons/ic-folder-blue.svg');
    }

    &.icon-feather-check-circle {
        background-image: url("../images/icons/SVG/Icon-feather-check-circle.svg");
    }

    &.icon-refresh {
        background-image: url("../images/icons/SVG/Icon-ionic-ios-refresh.svg");
    }

    &.icon-right-arrow-bg {
        background-image: url("../images/icons/SVG/icon-right-arrow-bg.svg");
    }

    &.icon-ios-list {
        background-image: url("../images/icons/SVG/Icon--ios-list.svg");
    }

    &.icon-left-arrow-grey {
        background-image: url("../images/icons/SVG/icon-left-arrow-grey.svg");
    }

    &.icon-left-arrow-blue {
        background-image: url("../images/icons/SVG/icon-left-arrow-blue.svg");
    }

    &.icon-right-arrow-grey {
        background-image: url("../images/icons/SVG/icon-right-arrow-grey.svg");
    }

    &.icon-right-arrow-blue {
        background-image: url("../images/icons/SVG/icon-right-arrow-blue.svg");
    }

    &.icon-ellipsis-grey {
        background-image: url("../images/icons/SVG/icon-ellipsis-grey.svg");
    }

    &.icon-ellipsis-horizontal {
        background-image: url("../images/icons/ic-ellipsis-horizontal.svg");
    }

    &.icon-search-white {
        background-image: url("../images/icons/ic_search_white.svg");
    }

    &.icon-messages-white {
        background-image: url("../images/icons/ic_messages_white.svg");
    }

    &.icon-notification-white {
        background-image: url("../images/icons/ic-notification-white.svg");
    }

    &.icon-info-spread-white {
        background-image: url("../images/icons/ic-info-spread-white.svg");
    }

    &.icon-invite-people-white {
        background-image: url("../images/icons/ic-invite-people-white.svg");
    }

    &.icon-discovery-white {
        background-image: url("../images/icons/ic-discovery-white.svg");
    }

    &.icon-topics-white {
        background-image: url("../images/icons/ic-topics-white.svg");
    }

    &.icon-library-white {
        background-image: url("../images/icons/ic-library-white.svg");
    }

    &.icon-profile-settings-white {
        background-image: url("../images/icons/ic-profile-settings-white.svg");
    }

   

    &.icon-profile-white {
        background-image: url("../images/icons/ic-profile-white.svg");
    }

    //card Icons
    &.icon-view {
        background-image: url("../images/icons/ic_view.svg");
    }

    &.icon-library-bookmark {
        background-image: url("../images/icons/ic_bookmark.svg");
    }

    &.icon-library-bookmark-blue {
        background-image: url("../images/icons/ic_bookmark_blue.svg");
    }

    &.icon-path {
        background-image: url("../images/icons/ic_lp.svg");
    }

    &.icon-heart-grey {
        background-image: url("../images/icons/ic-heart-grey.svg");
        width: 24px;
        height: 21px;
    }

    &.icon-comment-grey {
        background-image: url("../images/icons/ic-comment-grey.svg");
        width: 21px;
        height: 21px;
    }

    &.icon-lsp-blue {
        background-image: url("../images/icons/ic-lsp-blue.svg");
        width: 21px;
        height: 21px;
    }

    &.icon-library-box {
        background-image: url('../images/icons/icon-library-box.svg');
        width: 50px;
        height: 50px;
    }

    &.icon-learning-box {
        background-image: url('../images/icons/icon-learning-box.svg');
        width: 50px;
        height: 50px;
    }

    &.icon-upload-box {
        background-image: url('../images/icons/icon-upload-box.svg');
        width: 50px;
        height: 50px;
    }

    &.icon-split-horizontal {
        background-image: url('../images/icons/icon-split-horizontal.svg');
        width: 18px;
        height: 23px;
    }

    &.icon-split-vertical {
        background-image: url('../images/icons/icon-split-vertical.svg');
        width: 23px;
        height: 18px;
    }

    &.icon-people-grey {
        background-image: url('../images/icons/icon-people-grey.svg');
        width: 25px;
        height: 16px;
    }

    &.icon-settings-grey {
        background-image: url('../images/icons/icon-settings-grey.svg');
        width: 18px;
        height: 19px;
    }

    &.icon-blank-space {
        background-image: url('../images/icons/ic-blank-space.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-blank-space-active {
        background-image: url('../images/icons/ic-blank-space-active.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-2parts {
        background-image: url('../images/icons/ic-2parts.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-2parts-active {
        background-image: url('../images/icons/ic-2parts-active.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-3parts {
        background-image: url('../images/icons/ic-3parts.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-3parts-active {
        background-image: url('../images/icons/ic-3parts-active.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-4parts {
        background-image: url('../images/icons/ic-4parts.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-4parts-active {
        background-image: url('../images/icons/ic-4parts-active.svg');
        width: 120px;
        height: 89px;
    }

    &.icon-topics1-white {
        background-image: url('../images/icons/ic-topics1-white.svg');
    }

    &.icon-learning-space-white {
        background-image: url('../images/icons/ic-learning-space-white.svg');
    }

    &.icon-profile-grey {
        background-image: url('../images/icons/ic-profile-grey.svg');
    }

    &.icon-profile-active {
        background-image: url('../images/icons/ic-profile-active.svg');
    }

    &.ic-edit-blue {
        background-image: url('../images/icons/ic-edit.png');
        width: 30px;
        height: 30px;
    }

    &.ic-edit-blue2 {
        background-image: url('../images/icons/ic-edit-blue2.svg');
        width: 16px;
        height: 16px;
    }

    &.ic-location-blue {
        background-image: url('../images/icons/ic-location-blue.svg');
    }

    &.ic-location-black {
        background-image: url('../images/icons/ic-location-black.svg');
    }

    &.ic-url-blue {
        background-image: url('../images/icons/ic-url-blue.svg');
    }

    &.ic-instagram-blue {
        background-image: url('../images/icons/ic-instagram-blue.svg');
    }

    &.ic-instagram-blue-active {
        background-image: url('../images/icons/ic-instagram-active.svg');
    }

    &.ic-linkedin-blue {
        background-image: url('../images/icons/ic-linkedin-blue.svg');
    }

    &.ic-linkedin-blue-active {
        background-image: url('../images/icons/ic-linkedin-active.svg');
    }

    &.ic-mail-blue {
        background-image: url('../images/icons/ic-mail-blue.svg');
    }

    &.ic-facebook-blue {
        background-image: url('../images/icons/ic-facebook-blue.svg');
    }

    &.ic-facebook-blue-active {
        background-image: url('../images/icons/ic-facebook-active.svg');
    }

    &.ic-twitter-blue {
        background-image: url('../images/icons/ic-twitter-blue.svg');
    }

    &.ic-twitter-blue-active {
        background-image: url('../images/icons/ic-twitter-active.svg');
    }

    &.ic-twitter-grey {
        background-image: url('../images/icons/ic-twitter-gray.svg');
    }

    &.icon-url-grey {
        background-image: url('../images/icons/icon-url-grey.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-instagram-grey {
        background-image: url('../images/icons/icon-instagram-grey.svg');
    }

    &.icon-mail-grey {
        background-image: url('../images/icons/icon-mail-grey.svg');
    }

    &.icon-linkedIn-grey {
        background-image: url('../images/icons/icon-linkedIn-grey.svg');
    }

    &.icon-facebook-grey {
        background-image: url('../images/icons/icon-facebook-grey.svg');
    }

    &.ic-copy-link {
        background-image: url('../images/icons/ic_copy_link.svg');
    }

    &.ic-lock-blue {
        background-image: url('../images/icons/ic-lock-blue.svg');
        width: 16px;
        height: 21px;
    }

    &.ic-eye-hidden {
        background-image: url('../images/icons/ic-eye-hidden.svg');
        width: 22px;
        height: 21px;
    }

    &.icon-arrow-forward-blue {
        background-image: url('../images/icons/icon-arrow-forward-blue.svg');
        width: 14px;
        height: 8px;
    }

    &.icon-notes-grey {
        background-image: url('../images/icons/ic-notes-grey.svg');
        width: 18px;
        height: 19px;
    }

    &.icon-folder-grey {
        background-image: url('../images/icons/ic-folder-grey.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-document-grey {
        background-image: url('../images/icons/ic-document-grey.svg');
        width: 18px;
        height: 19px;
    }

    &.icon-image-grey {
        background-image: url('../images/icons/icon-img.png');
        width: 18px;
        height: 18px;
    }

    &.icon-audio-grey {
        background-image: url('../images/icons/ic-audio-grey.svg');
        width: 18px;
        height: 18px;

    }

    &.icon-video-grey {
        background-image: url('../images/icons/ic-video-grey.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-video-with-bg {
        background-image: url('../images/icons/ic-video-with-bg.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-url-grey {
        background-image: url('../images/icons/icon-web-link.png');
        width: 30px;
        height: 30px;
    }

    &.icon-msword {
        background-image: url('../images/icons/icon-msword.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-ppt {
        background-image: url('../images/icons/icon-ppt.png');
        width: 18px;
        height: 18px;
    }

    &.icon-excel {
        background-image: url('../images/icons/icon-excel.png');
        width: 18px;
        height: 18px;
    }

    &.icon-notes {
        background-image: url('../images/icons/icon-notes.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-pdf {
        background-image: url('../images/icons/icon-pdf.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-url-grey2 {
        background-image: url('../images/icons/ic-url-grey2.svg');
        width: 20px;
        height: 18px;
    }

    &.icon-url-activity-with-bg {
        background-image: url('../images/icons/activity-with-bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-url-greyactivity {
        // background-image: url('../images/rawimages/activity50.svg');
        background-image: url('../images/icons/icon-activity-white.png');
        
        width: 20px;
        height: 18px;
    }

    &.icon-url-greyactivity2 {
        background-image: url('../images/rawimages/activity24.svg');
        background-image: url('../images/icons/activity-with-bg.png');
        
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-greyactivity {
        background-image: url('../images/icons/activity_library.png');
        width: 19px !important;
        height: 23px !important;
    }

    &.icon-url-spaces {
        background-image: url('../images/rawimages/illustration_Astronaut.svg');
        width: 187px;
        height: 193px;
    }

    &.icon-url-mail {
        background-image: url('../images/rawimages/msg.png');
        width: 100px;
        height: 100px;
        margin-left: 189px;
        margin-top: 150px;
    }

    &.icon-url-connection {
        background-image: url('../images/rawimages/illustration_puzzle.svg');
        width: 187px;
        height: 193px;
    }

    &.icon-url-learningspace {
        background-image: url('../images/rawimages/ls-empty.svg');
        width: 80px;
        height: 80px;
    }

    &.icon-url-learningpath {
        background-image: url('../images/rawimages/lp-empty.png');
        width: 80px;
        height: 80px;
    }

    &.icon-url-notification {
        background-image: url('../images/icons/empty_notification.png');
        width: 36px;
        height: 36px;
    }

    &.icon-books-grey {
        background-image: url('../images/icons/ic-books-grey.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-url-ls-gray {
        background-image: url('../images/icons/activity_library.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0px 0px 0px 3px;
    }

    &.icon-zip-grey {
        background-image: url('../images/icons/ic-zip-grey.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-back-arrow-blue {
        background-image: url('../images/icons/ic-back-arrow-blue.svg');
        width: 22px;
        height: 14px;
    }

    &.icon-copy-clipboard-grey {
        background-image: url('../images/icons/icon-copy-clipboard-grey.svg');
        width: 27px;
        height: 21.2px;
    }

    &.icon-plus-border-grey {
        background-image: url('../images/icons/icon-plus-border-grey.svg');
        width: 22px;
        height: 22px;
    }

    &.icon-copy-link-grey {
        background-image: url('../images/icons/icon-copy-link-grey.svg');
        width: 22px;
        height: 9.95px;
    }

    &.icon-bug-grey {
        background-image: url('../images/icons/ic-bug-grey.svg');
        width: 100px;
        height: 100px;
    }

    &.ic-full-screen-grey {
        background-image: url('../images/icons/ic-full-screen-grey.svg');
        width: 18px;
        height: 18px;
    }

    &.ic-cancel-blue-circle {
        background-image: url('../images/icons/ic-cancel-blue-circle.svg');
    }

    &.ic-open-new-tab-grey {
        background-image: url('../images/icons/ic-open-new-tab-grey.svg');
        width: 18px;
        height: 18px;
    }

    &.icon-tickmark-blue {
        background-image: url('../images/icons/ic-checkmark-blue.svg');
        width: 20px;
        height: 18px;
    }

    &.icon-tickmark-white {
        background-image: url('../images/icons/ic-checkmark-white.svg');
        width: 20px;
        height: 18px;
    }

    &.icon-drag-grey {
        background-image: url('../images/icons/ic-drag-grey.svg');
        width: 24px;
        height: 24px;
    }

    &.icon-round-plus-blue {
        background-image: url('../images/icons/ic-round-plus-blue.svg');
        width: 24px;
        height: 24px;
    }

    &.byndr-logo {
        background-image: url('../images/logo.png');
        width: 120px;
        height: 30px;
        margin: 0px auto;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.icon-space-count {
        background-image: url('../images/icons/icon_spaces.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

}


.icon-move-gray,
.move_button span.iconwrap {
    background-image: url("../images/icons/move-gray.png");
}

.icon-move-blue,
.move_button:hover span.iconwrap {
    background-image: url("../images/icons/move-blue.png");
}


.icon-delete-gray,
.delete_button span.iconwrap {
    background-image: url("../images/icons/delete-gray.png");
}

.icon-delete-blue,
.delete_button:hover span.iconwrap {
    background-image: url("../images/icons/delete-blue.png");
}

.move_button span.iconwrap {
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-position: 0 0;
    display: inline-block;
}


.delete_button span.iconwrap {
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-position: 0 0;
    display: inline-block;
}

.learningicon {
    & .icon {
        width: 40px;
        height: 40px;
    }
}

.icon_published {
    background-image: url("../images/icons/published_icon.png");

}

.icon-thought {
    background-image: url("../images/icons/icon_thought.png");
    width: 60px;
    height: 60px;
}

.popIcons {
    .icon {
        &.icon-url-activity-with-bg{
            background-image: url('../images/icons/url-activity-light.png');
            &.active{
                background-image: url('../images/icons/url-activity-light-active.png');
            }
            
        }
        &.icon-audio-grey {
            background-image: url('../images/icons/audio-light.png');
            &.active{  background-image: url('../images/icons/audio-light-active.png');}
        }

        &.icon-image-grey {
            background-image: url('../images/icons/icon-image-light.png');
            &.active{  background-image: url('../images/icons/icon-image-light-active.png');}
        }

        &.icon-url-grey {
            background-image: url('../images/icons/icon-url-light.png');
            &.active{background-image: url('../images/icons/icon-url-light-active.png');}
        }
        &.icon-video-with-bg {
            background-image: url('../images/icons/video-light.png');
            &.active{background-image: url('../images/icons/video-light-active.png');}
        }
        &.icon-excel {
            background-image: url('../images/icons/icon-excel-light.png');
            &.active{
                background-image: url('../images/icons/icon-excel-light-active.png');
            }
        }

        &.icon-ppt {
            background-image: url('../images/icons/icon-ppt-light.png');
            &.active{ background-image: url('../images/icons/icon-ppt-light-active.png');}
        }

        &.icon-default-bg {
            background-color: #f3f3f3;
            &.active{ background-color: #2d68f8;}
        }
        &.icon-pdf-bg {
            background-color: #f3f3f3;
            &.active{ background-color: #2d68f8;}
        }
    }
}

.dark-theme {

    .popIcons {
        .icon {

&.icon-url-activity-with-bg{
    background-image: url('../images/icons/url-activity-dark.png');
    &.active{
        background-image: url('../images/icons/url-activity-dark-active.png');
    }
    
}

            &.icon-audio-grey {
                background-image: url('../images/icons/audio-dark.png');
                &.active{
                    background-image: url('../images/icons/audio-dark-active.png');
                }
            }

            &.icon-image-grey {
                background-image: url('../images/icons/icon-image-dark.png');
                &.active{
                    background-image: url('../images/icons/icon-image-dark-active.png');
                }
            }

            &.icon-url-grey {
                background-image: url('../images/icons/icon-url-dark.png');
                &.active{
                    background-image: url('../images/icons/icon-url-dark-active.png');
                }
            }

          
            &.icon-video-with-bg {
                background-image: url('../images/icons/video-dark.png');
                &.active{
                    background-image: url('../images/icons/video-dark-active.png');
                }
            }

            &.icon-excel {
                background-image: url('../images/icons/icon-excel-dark.png');
                &.active{
                    background-image: url('../images/icons/icon-excel-dark-active.png');
                }
            }

            &.icon-ppt {
                background-image: url('../images/icons/icon-ppt-dark.png');
                &.active{background-image: url('../images/icons/icon-ppt-dark-active.png');}
            }
            &.icon-pdf-bg {
                background-color: #272727;
                &.active{ background-color: #fff;}
            }

            &.icon-default-bg { 
                background-color: #272727;
                &.active{ background-color: #fff;}
            }
&.icon-text{
    &.active{ background-color: #000 !important; color: #fff !important; }
}
        }
    }
}

.icon-like {
    background-image: url("../images/icons/icon-like2x.png");
    &.filled{
        background-image: url("../images/icons/icon-like-filled2x.svg");
    }
}
.icon-comment{
    background-image: url("../images/icons/icon-comment2x.png");
    &.filled{
        background-image: url("../images/icons/icon-comment2x-filled.svg");
    }
}
.icon-bookmark{
    background-image: url("../images/icons/icon-save2x.png");
    &.filled{
        background-image: url("../images/icons/icon-save-filled2x.svg");
    }
}
.icon-share{
    background-image: url("../images/icons/icon-share2x.png");
   }

   .dark-theme
   {
   & .icon-like {
        background-image: url("../images/icons/icon-like-dark.png");
        &.filled{
            background-image: url("../images/icons/icon-like-filled2x.svg");
        }
    }
   & .icon-bookmark{
        background-image: url("../images/icons/icon-bookmark-white.png");
        &.filled{
            background-image: url("../images/icons/icon-save-filled2x.svg");
        }
    }
   & .icon-share{
        background-image: url("../images/icons/icon-share-white.png");
       }
       .icon-comment{
        background-image: url("../images/icons/icon-comment-white.png");
        &.filled{
            background-image: url("../images/icons/icon-comment2x-filled.svg");
        }
    }

   }
   .icon_light_on, .icon_dark_on{
    
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
   }
   .icon_light_on{
    background-image: url("../images/icons/icon_light_on.png");
   }
   .icon_dark_on{
    background-image: url("../images/icons/icon_dark_on.png");

   }
.dailogFull{
 &  .icon-bookmark{    background-image: url("../images/icons/bookmark-light-default.png");
&.filled{    background-image: url("../images/icons/bookmark-light-filled.png");}
}
& .icon-like{    background-image: url("../images/icons/like-light-default.png");
    &.filled{    background-image: url("../images/icons/like-light-filled.png");}
}
& .icon-comment{    background-image: url("../images/icons/comment-light-default.png");
    &.filled{    background-image: url("../images/icons/comment-light-filled.png");}
}
& .icon-share{    background-image: url("../images/icons/share-light-default.png");
    
}

&.dark-theme{
    &  .icon-bookmark{    background-image: url("../images/icons/bookmark-dark-default.png");
        &.filled{    background-image: url("../images/icons/bookmark-dark-filled.png");}
        }
        & .icon-like{    background-image: url("../images/icons/like-dark-default.png");
            &.filled{    background-image: url("../images/icons/like-dark-filled.png");}
        }
        & .icon-comment{    background-image: url("../images/icons/comment-dark-default.png");
            &.filled{    background-image: url("../images/icons/comment-dark-filled.png");}
        }
        & .icon-share{    background-image: url("../images/icons/share-dark-default.png");
            
        }

}
}
.icon-fullscreensmall{    background-image: url("../images/icons/full-screen-small.png"); display: inline-block; width: 30px; height: 30px; background-size: 30px 30px;}

.accept {
    background-image: url('../images/icons/accept.png');
    width: 20px !important;
    height: 20px !important;
    background-size: 20px 20px !important;
    background-repeat: no-repeat;
    padding: 0 !important;
    min-width: 20px !important;
    
}


 .reject {
    background-image: url('../images/icons/reject.png');
    width: 20px !important;
    height: 20px !important;
    background-size: 20px 20px !important;
    background-repeat: no-repeat;
    padding: 0 !important;
    min-width: 20px !important;
}

.connect_request{
    
    background-image: url('../images/icons/connection-requests.png');
    width: 43px !important;
    height: 31px !important;
    display: inline-block;
    background-size: 43px 31px;
}
.title-list  .library-blue { background-image: url('../images/icons/library-breadcrumb.png') !important;}


.icon-move span.icons {
    background-image: url('../images/icons/move.png');
    width: 24px;
    height: 24px;
    display: inline-block; background-size: 24px 24px;
    &:hover{  background-image: url('../images/icons/move-active.png');}
}

.icon-rename span.icons {
    background-image: url('../images/icons/rename.png');
    width: 24px;
    height: 24px;
    display: inline-block; background-size: 24px 24px;
    &:hover{  background-image: url('../images/icons/rename-active.png');}
}

.icon-remove span.icons {
    background-image: url('../images/icons/remove.png');
    width: 24px;
    height: 24px;
    display: inline-block; background-size: 24px 24px;
    &:hover{  background-image: url('../images/icons/remove-active.png');}
}

.icon-nopreview{
    
    background-image: url('../images/icons/no-preview.png');
    width: 50px;
    height: 50px;
    display: inline-block; background-size: 50px 50px;
}

.icon-upload{
    background-image: url('../images/icons/icon-upload.png');
    width:24px !important; height: 24px !important;
    margin-right: 10px !important;
}
.icon-folder{
    background-image: url('../images/icons/icon-newfolder.png');
    width:24px !important; height: 21px !important;
    margin-right: 10px !important;
}

.icon-addurl{
    background-image: url('../images/icons/icon-url.png');
    width:24px !important; height: 12px !important;
    margin-right: 10px !important;
}

.icon-newactivity{
    background-image: url('../images/icons/icon-activity.png');
    width:24px !important; height: 28px !important;
    margin-right: 15px !important;
}