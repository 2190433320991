 .light_theme {
     $light_primary_color: #0377FF;
     $opacity_color: #F4F7FF;


     & button {


         &.btn-submit {
             background-color: $light_primary_color;
             color: $color-white;

             border: 1px solid $light_primary_color;



             &:focus {
                 border: 1px solid $light_primary_color;
                 color: $color-white;
             }



             &.mat-button-disabled {
                 background-color: $light_primary_color  !important;

                 color: $color-white  !important;


             }

             &:active {
                 background-color: $light_primary_color;
                 color: $color-white;
             }
         }

         &.btn-upload {
             background-color: $color-white;
             color: $light_primary_color;

             border: 1px solid $light_primary_color;


         }

         &.btn-upload-transparent {
             background-color: transparent;
             color: $light_primary_color;

             border: 1px solid $light_primary_color;


         }

         &.btn-resend {
             background-color: $light_primary_color;
             border: 1px solid $light_primary_color;
             color: $light_primary_color;


             &.resend-confirm,
             &:focus {
                 background-color: $light_primary_color;
                 border: 1px solid $light_primary_color;
                 color: $color-white;
             }
         }
     }

     & .btn-primary {

         background-color: $light_primary_color  !important;
         border-color: $light_primary_color  !important;

         &.mat-button-disabled {
             opacity: 0.3 !important;
             color: #fff !important;
         }
     }

     & .btn-primary:not(:disabled):not(.disabled).active,
     & .btn-primary:not(:disabled):not(.disabled):active,
     & .show>.btn-primary.dropdown-toggle {
         background-color: $light_primary_color;
         border-color: $light_primary_color;
         color: $color-white;
     }




     & .icon {


&.icon-invite{   background-image: url("../images/light_theme/invite.png");}

         &.icon-left-home-logo {
             background-image: url("../images/light_theme/home_normal.png");
         }

         &.icon-library-nav {
             background-image: url("../images/light_theme/library_normal.png");
         }

         &.icon-lsp-nav {
             background-image: url("../images/light_theme/space_normal.png");
         }


         &.icon-lp {
             background-image: url("../images/light_theme/path_normal.png");
         }

         &.icon-settings {
             background-image: url("../images/light_theme/settings_normal.png");
         }

         &.icon-logo {
             background-image: url("../images/light_theme/logo.png");
         }
     }


     & .active {
         & .icon {
             &.icon.icon-lp {
                 background-image: url("../images/light_theme/path_active.png");
             }

             &.icon-left-home-logo {
                 background-image: url("../images/light_theme/home_active.png");
             }

             &.icon-library-nav {
                 background-image: url("../images/light_theme/library_active.png");
             }

             &.icon-lsp-nav {
                 background-image: url("../images/light_theme/space_active.png");
                 cursor: auto !important;
             }

             &.icon-settings {
                 background-image: url("../images/light_theme/settings_active.png");
             }

         }
     }

     & .icon {
         &.icon-library-blue {
             background-image: url("../images/light_theme/library_active.png");
         }
     }



     & .left-sidebar {
         background: #fff;
     }

     & .views_desc {
         &.pathClr {
             color: #12C464 !important;
         }

         &.spaceClr {
             color: $light_primary_color  !important;
         }
     }


     .tabsWrap {
         & li {
             & a {
                 border: 1px solid #000;
                 color: #000;

                 &:hover,
                 &.active {
                     background: $light_primary_color;
                     color: #fff;
                     border-color: $light_primary_color;
                 }
             }
         }
     }



     & .template-box {
         & .template-img {

             &:hover {
                 border: 2px solid $light_primary_color  !important;
                 background: $opacity_color  !important;
             }
         }

         &.active-template {
             & .p-small-black15 {
                 color: $light_primary_color  !important;
             }

             & .template-img {
                 border: 2px solid $light_primary_color  !important;
                 background: $opacity_color  !important;
             }
         }

     }

     .mat-tab-group {
         .mat-tab-label {


             &.mat-tab-label-active {
                 border: 1px solid $light_primary_color;

                 .mat-tab-label-content {
                     color: $light_primary_color;
                 }
             }
         }
     }



     & .profilename {
         & a {
             color: $light_primary_color;
         }
     }

     & .profile-navtabs .mat-tab-label {
         border: 1px solid #000;
     }


     & .profile-navtabs .mat-tab-label-active {

         color: $color-white  !important;
         background-color: $light_primary_color;

         & .mat-tab-label-content {
             color: $color-white  !important;
         }
     }

     & .left-profile{
        padding-top:20px;
     }

     & .profile-details {
        
       

         & h3 {
             color: #fff;
         }

         & p {
             color: #fff;
         }
     }


     .learningspacebox {
         background: #19d0f8;
         background: linear-gradient(-154deg, #19d0f8 0.00%, #005dff 100.00%);

         & h4 {
             color: #fff;
         }

         & p {
             color: #fff;
         }
     }

     .learningpathbox {

         background: #d4ff6a;
       
         background: transparent linear-gradient(236deg, #D4FF6A 0%, #00D0A4 100%) 0% 0% no-repeat padding-box;
         

         & h4 {
             color: #fff;
         }

         & p {
             color: #fff;
         }
     }

     & .icon {
         &.icon_card_space {
             width: 33px;
             height: 33px;
             background-image: url("../images/light_theme/space_card.png");
             background-size: 33px 33px;
             display: inline-block;
         }

         &.icon_card_path {
             width: 33px;
             height: 33px;
             background-image: url("../images/light_theme/path_card.png");
             background-size: 33px 33px;
             display: inline-block;
         }
     }

     & .log-img span {
         background-image: url("../images/light_theme/logout.png");
     }
     .left-icons a:hover {
        background: #f5f5f5;
        & i{ color: #000 !important;}
    }
   
    .opensidebar .profile-sec-inner {
        background-color: #f5f5f5;
   
    }

.invite_wrap_inner{ background: #f5f5f5;}



.left-icons .active i {
  color: $light_primary_color;
}
.home-icon a{ text-decoration: none;}

 & .home-icon i{ color: #000; font-size:22px ; font-weight: 700;}

& .logo-section i{ font-size:18px !important;}
& .custom-btn{ background: $light_primary_color; color: #fff;}
& .invitelink{ color: $light_primary_color;}
& .logout i{ color: #000;}

& .profile-sec-inner p{ color: #000;}
.mat-progress-bar-fill::after {
    background-color: $light_primary_color;
}
.header-main {
& .notificationIcon{ & .mat-icon{ color: #000; }
&:hover{& .mat-icon{ color: $light_primary_color;}}
}
& .faqIcon { & .mat-icon{ color: #000;}
&:hover{& .mat-icon{ color: $light_primary_color;}}
}
}

app-library-info 
{ 


& .mat-tab-label.mat-tab-label-active {  background:$light_primary_color;
    
    & .mat-tab-label-content{
    color:#fff !important;
    & .mat-button-wrapper{ color: #fff !important;}
}
}
}

.uploadBtn {
    background-color: $light_primary_color !important;
}

&  app-questionnaire-create{
    & .mat-tab-label  button{ background: #ebe9e9; color: #000 !important;}
    & .mat-tab-label-active button{  background-color: $light_primary_color !important; color: #fff !important;
    & .mat-button-wrapper{ color: #fff !important;}
    }
        }

        
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $light_primary_color !important;
    color: #fff;
}

button.btn-submit.view_color{
    background: #EDF5FF;
    color: #0377FF;
    border:0; font-size: 16px;
    & span{ font-weight: 600;}

 }

 
 @media screen and (max-width: 1023px)
 {
 .home-icon .icon.icon-logo{

    background-color: #fff
 }
}
    }

   
    
 