@import "./variables";
@import "./icons";
@import "./light_theme";
@import "./dark_theme";
@import "./blue_theme";
@import "./green_theme";



@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap');
// 0px margin for all header tags and paragraph
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0px;
}

ul {
    padding: 0px;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab
{
    font-family: 'Inter' !important;
}

body,
p,
span,
li,
a,
div,
h5,
h6,
input,
textarea,
select {
    font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

// all components width to 100%
app-signup,
app-login,
app-forgot-password,
app-confirmation-link,
app-reset-password,
app-password-confirmation-link,
app-terms-of-service,
app-intro,
app-areas-of-interest,
app-tour-popup,
app-topic-feeds,

.width100p {
    width: 100%;
}






// custom scrollbar
.scroll-bar {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: $color-lightgrey12;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: $color-lightgrey12;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555;
    }
}

// .scroll-bar-x {
//     overflow-x: auto;

//     &::-webkit-scrollbar-track {
//         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//         border-radius: 10px;
//         background-color: #f5f5f5;
//     }

//     &::-webkit-scrollbar {
//         height: 6px;
//         background-color: #f5f5f5;
//     }

//     &::-webkit-scrollbar-thumb {
//         border-radius: 10px;
//         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//         background-color: $color-blue1;
//     }
// }

// global paddings
.p-20 {
    padding: 20px;
}

.p-10 {
    padding: 10px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.ptl-20 {
    padding: 20px 0px 0px 20px;
}

.pr-20 {
    padding-right: 20px;
}

.pb-20 {
    padding-bottom: 10px;
    padding-right: 10px;
}

.prb-20 {
    padding: 0px 20px 20px 0px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.pr-10 {
    padding-right: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pr-15 {
    padding-right: 15px;
}

// font sizes for header tags
.h1-36 {
    font-size: $font-36;
    line-height: $font-42;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: $color-blue3;
}

.h2-26 {
    font-size: $font-26;
    line-height: $font-34;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: $color-blue3;
}

.h3-24 {
    font-size: $font-24;
    line-height: $font-30;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: $color-blue1;
}


.h3-22-black {
    font-size: $font-22;
    line-height: $font-30;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: $color-black;
}


.h3-24-black {
    font-size: $font-24;
    line-height: $font-30;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: $color-black;
}

.h4-20-black {
    color: $color-black;
    font-size: $font-20;
    line-height: $font-26;
}
.h4-24-black {
    color: $color-black;
    font-size: $font-24;
    line-height: $font-26;
    font-weight: 600;
}


.h4-20-blue {
    color: $color-blue1;
    font-size: $font-20;
    line-height: $font-26;
}

.h5-white-18 {
    color: $color-white;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: $font-18;
}

.h5-blue-18 {
    color: $color-blue7;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: $font-18;
    line-height: $font-28;
}

.h5-blue2-18 {
    color: $color-blue2;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: $font-18;
    line-height: $font-28;
}

.h5-16-black {
    font-size: $font-16;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: $font-22;
    color: $color-black;
}

.h6-15-blue {
    color: $color-blue1;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: $font-15;
    line-height: $font-22;
}

.h6-15 {
    color: $color-black;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: $font-15;
    line-height: $font-22;
}

.p-large-18 {
    font-size: $font-18;
    line-height: $font-26;
}

.p-small-white14 {
    color: $color-white;
    font-size: $font-14;
    line-height: $font-20;
}

.p-small-black15 {
    color: $color-black;
    font-size: $font-15;
    line-height: $font-20;
}

.p-small-black14 {
    font-size: $font-14;
    color: $color-black3;
    line-height: $font-20;

    b {
        font-family: 'Inter', sans-serif;
    font-weight: 500;
    }
}

.p-small-black12 {
    font-size: $font-12;
    color: $color-black3;
    line-height: $font-20;
}

.p-small-grey14 {
    font-size: $font-14;
    color: $color-lightgrey14;
    line-height: $font-20;
}

.p-small-blue-14 {
    font-size: $font-14;
    color: $color-blue1;
    line-height: $font-20;
}

.p-small-blue-12 {
    font-size: $font-12;
    line-height: $font-18;
    color: $color-blue1;
}

.p-small-blue-16 {
    font-size: $font-16;
    color: $color-blue1;
    line-height: $font-24;
}

.p-small-grey-16 {
    font-size: $font-16;
    color: $color-black-50;
    line-height: $font-24;
}

.p-small-blue-15 {
    font-size: $font-15;
    color: $color-blue1;
    line-height: $font-20;
}

.align-center {
    text-align: center;
}

.justify-center {
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}

.two-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.single-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.three-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.four-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

// global styles
.box-main {
    background-color: $color-white;
    box-shadow: 0px 0px 0.2px rgba(0, 0, 0, 0.8);
    border-radius: 15px;
}

.box-padding-large {
    padding: 35px 50px;
}

.box-padding-medium {
    padding: 25px 30px;
}

.w-100,
.mat-form-field {
    width: 100%;
}

// material angular global scss
.mat-form-field-appearance-outline {
    .mat-form-field-infix {
        padding: 4px 12px 12px 8px;

        input::placeholder,
        textarea::placeholder {
            font-size: $font-14;
            color: $color-lightgrey14;
            opacity: 1;
            /* Firefox */
        }
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
        top: 0em !important;
        font-size: 14px;
    }

    .mat-form-field-outline-thick .mat-form-field-outline-start,
    .mat-form-field-outline-thick .mat-form-field-outline-end,
    .mat-form-field-outline-thick .mat-form-field-outline-gap {
        border-width: 1px !important;
    }

    .mat-form-field-outline {
        color: $color-lightgrey3;
    }

    &.mat-focused .mat-form-field-outline-thick,
    &.ng-valid .mat-form-field-outline {
        color: $color-black;
    }

    &.password-field.ng-valid .mat-form-field-outline {
        color: $color-green;
    }

    &.mat-pb-0 {
        .mat-form-field-wrapper {
            padding-bottom: 0px;
        }
    }

    &.black-border {
        .mat-form-field-outline {
            color: $color-lightgrey14;
        }
    }
}

.mat-form-field-suffix {
    .required-text {
        color: $color-red;
    }

    .black-text-suffix {
        color: $color-lightgrey14;
    }
}

.mat-textarea {
    .mat-form-field-flex {
        border: 1px solid $color-lightgrey16;
        padding: 10px 10px 10px 20px;
        border-radius: 5px;

        .mat-form-field-infix {
            padding-top: 0px;
            margin-top: 0px;
            border-top: 0px;

            textarea {
                min-height: 60px;
                font-size: 14px !important;
                resize: none;
            }

            label.mat-form-field-label {
                font-size: 14px;
                line-height: 17px;
                color: $color-lightgrey5;
            }
        }
    }

    .mat-form-field-underline,
    .mat-form-field-ripple {
        height: 0px !important;
    }
}

.password-icon {
    .mat-icon {
        position: relative;
        top: 6px;
        right: -6px;
        color: $color-lightgrey2;
    }

    button:focus {
        outline: none;
    }
}

.mat-checkbox {
    .mat-checkbox-layout {
        margin-bottom: 0px;

        .mat-checkbox-label {
            color: $color-lightgrey9;
            font-size: $font-13;
            position: relative;
            top: 2px;
        }
    }

    &.checkbox-error {

        .mat-checkbox-label,
        .mat-checkbox-label a {
            color: $color-red;
        }

        .mat-checkbox-frame {
            border-color: $color-red;
        }
    }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $color-blue1;
}

.mat-form-field-subscript-wrapper,
.accept-checkbox {
    margin-top: 5px;

    .mat-checkbox-label {
        white-space: normal;
    }

    .mat-checkbox-inner-container {
        margin-top: 14px;
    }

    .mat-error {
        position: relative;
        left: -10px;

        .exclamation-symbol {
            width: 13px;
            height: 13px;
            color: $color-white;
            background: $color-red;
            display: inline-block;
            border-radius: 50%;
            margin-right: 5px;

            span {
                position: relative;
                left: 5px;
                top: 1px;
                font-size: 12px;
            }
        }
    }
}

.accept-checkbox {
    .mat-error {
        left: 0px;
        margin-top: 5px;

        .password-error {
            .exclamation-symbol {
                margin-top: 1px;

                span {
                    top: -6px;
                }
            }

            p {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

mat-password-strength {
    .mat-progress-bar {
        height: 0px;
    }
}

mat-password-strength-info {
    .mat-card {
        margin-top: -2px;
        padding: 12px 20px;
    }
}

.mat-icon.mat-primary {
    color: $color-green;
}

mat-form-field .mat-form-field {
    &-underline {
        position: static;
    }

    &-subscript-wrapper {
        position: static;
    }
}

.mat-error {
    color: $color-red;
    background: $color-red-10;
    padding: 16px 10px 16px 20px;
    width: calc(100% + 25px);
    
    font-size: 14px;
    font-weight: 500;
}

.password-error {
    display: flex;
    flex-flow: row;

    .not-strong {
        font-size: $font-14;
        font-family: 'Inter', sans-serif;
    font-weight: 500;
    }

    .password-info {
        padding-left: 18px;
        padding-top: 5px;
    }

    p {
        width: calc(100% - 18px);
    }
}

.mat-form-field-wrapper {
    padding-bottom: 15px;
}

.select-box {
    &.mat-form-field-appearance-fill .mat-form-field-flex {
        padding: 0em 20px 0 20px;
        border-radius: 3px;
        margin-right: 8px;
        width: 100%;
        max-width: 200px;

        .mat-form-field-infix {
            border-top: 0px solid transparent;
            padding: 6px 0px 6px 0px !important;
        }
    }

    .mat-form-field-underline::before,
    .mat-form-field-ripple {
        height: 0px !important;
    }

    .mat-select-arrow-wrapper {
        position: relative;
        top: 10px;
        left: 8px;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }

    .mat-select-value-text {
        font-size: $font-15;
        line-height: $font-18;
    }
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    line-height: 35px !important;
    height: 35px !important;
    font-size: $font-14  !important;
    color: $color-black-80;
}

.search-field .mat-form-field-outline {
    background-color: $color-white;
}

.discovery-search .mat-form-field-outline,
.discovery-search .mat-form-field-outline-end,
.discovery-search .mat-form-field-outline-start {
    border: 0px !important;
    border-radius: 5px;
}

.invite-people {
    .mat-form-field-flex {
        border: 1px solid $color-lightgrey16;
        border-radius: 5px;
        padding: 12px 15px;
        min-height: 100px;

        .mat-form-field-infix {
            padding: 0px;
            border: 0px;
            font-size: $font-14;
            line-height: $font-22;
            position: relative;
            top: -6px;
        }
    }

    .mat-chip-list-wrapper {
        padding-top: 25px;
    }

    .mat-form-field-label {
        padding-top: 15px;
    }

    &.mat-form-field-appearance-legacy {

        .mat-form-field-underline,
        .mat-form-field-ripple {
            height: 0px;
        }
    }

    .mat-chip {
        line-height: $font-18;
        font-size: $font-14;
        padding: 8px 15px;
        height: auto;
    }

    .mat-chip.mat-standard-chip {
        background-color: $color-blue6;
        color: $color-blue2;
    }

    .mat-standard-chip:hover::after,
    .mat-standard-chip:focus::after {
        opacity: 0;
    }

    .mat-chip.mat-standard-chip .mat-chip-remove {
        opacity: 1;
    }

    .mat-standard-chip .mat-chip-remove,
    .mat-standard-chip .mat-chip-trailing-icon {
        margin-left: 12px;
    }
}

.mat-standard-chip {
    height: auto !important;
}

.area-of-interest-section {
    .mat-chip.mat-standard-chip {
        background-color: $color-white;
        color: $color-black3;
        padding: 8px 12px 6px 15px;
        justify-content: center;
        border: 1px solid $color-lightgrey31;
    }

    // .mat-chip.mat-standard-chip {
    //     background-color: $color-blue6;
    //     color: $color-blue2;
    //     padding: 5px 15px 1px 6px;
    // }
    // input.mat-chip-input {
    //     border: 1px solid $color-black-10;
    //     padding: 10px 15px;
    //     border-radius: 5px;
    //     width: 100%;
    //     color: $color-lightgrey14;
    //     flex: initial;
    //     margin-top: 10px !important;
    // }
    // .mat-form-field-appearance-legacy .mat-form-field-underline {
    //     display: none;
    // }
}

.search-connections-profile {
    &.user-row {
        padding-left: 0px;
    }

    .user-img,
    .placeholder-name {
        width: 40px !important;
        height: 40px !important;
    }

    .placeholder-name h2 {
        font-size: $font-20  !important;
        line-height: $font-24  !important;
    }

    h6.h6-15 {
        width: calc(60vw - 285px);
    }
}

.connections-requests-list .search-connections-profile h6.h6-15 {
    width: 180px;
}

.mat-menu-panel button.mat-menu-item {
    display: flex;
    align-items: center;
    line-height: $font-16;
    font-size: $font-16;

    // margin: 5px 0px !important;
    .icon {
        width: 28px;
        height: 28px;
        margin-right: 5px;

        &.icon-share {
            width: 16px !important;
            height: 18px !important;
            margin-right: 14px;
        }

        &.icon-copy {
            width: 16px !important;
            height: 19px !important;
            margin-right: 14px;
        }

        &.icon-rename {
            width: 19px !important;
            height: 19px !important;
            margin-right: 11px;
        }

        &.icon-favourite-dark {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
        }

        &.icon-info-dark {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
        }

        &.icon-delete {
            width: 18px !important;
            height: 20px !important;
            margin-right: 12px;
        }

        &.icon-upload-grey {
            width: 18px !important;
            height: 18px !important;
            margin-right: 11px;
        }
    }

    .choose-computer-label {
        display: flex;
        align-items: center;
    }

    &:focus {
        outline: none;
    }
}

.mat-icon-button:focus {
    outline: none;
}


.notes-icon-menu .mat-icon-button {
    padding: 0px;
    min-width: 0;
    margin: 0px;
    width: auto;
    height: auto;
    flex-shrink: 0;
    line-height: 0px;
    border-radius: 50%;
    position: relative;
    top: -4px;
}

// material dialog
.mat-dialog-container {
    padding: 0px !important;
    border-radius: 20px !important;
}

.confirmationDialogContainer {
    & .mat-dialog-container {
        padding: 0 24px 10px !important
    }
}

.mat-snack-bar-container {
    color: $color-white;
    background: $color-green6;

    .mat-simple-snackbar span {
        font-family: 'Inter', sans-serif;
    font-weight: 500;
    }

    .mat-simple-snackbar-action button {
        color: $color-white;
    }
}

.spinner-white .mat-spinner circle {
    stroke: $color-white;
}

// ktd-grid-item {
//     padding: 10px;
//     .grid-item-resize-icon {
//         right: 15px !important;
//         bottom: 15px !important;
//     }
// }

// country flag country code scss
.iti--allow-dropdown {
    width: 100%;
    margin-bottom: 20px;

    input {
        padding: 12px 20px 8px 65px !important;
        border: 1px solid $color-lightgrey3;
        border-radius: 5px;
        width: 100%;
        font-size: 15px;
        color: $color-black;

        &:focus {
            outline: none;
        }
    }

    .iti__selected-flag {
        padding: 0 12px 0 24px;
    }
}

.search-container input {
    padding-left: 22px !important;
}

// mat progress bar scss
.mat-progress-bar.loading-progress-bar {
    position: absolute;
    top: 60px;

    .mat-progress-bar-buffer {
        background-color: $color-lightgrey4;
    }

    .mat-progress-bar-fill::after {
        background-color: $color-lightgrey23;
    }
}

.grid-loading {
    ngx-skeleton-loader {
        span.loader {
            width: calc(20% - 20px);
            margin-right: 20px;
        }
    }
}

//buttons global scss


.button-black:not(:disabled):not(.disabled).active,
.button-black:not(:disabled):not(.disabled):active {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;
}

button {
    box-shadow: none !important;
    border-radius: 3px;

    &.btn-submit {
        font-size: $font-16;
        line-height: $font-26;
     span {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        }

      

        &.btn-black {
            background-color: $color-black;
            border: 1px solid $color-black;
        }

        &.mat-button-disabled {
          
            opacity: 0.5;
            &.btn-black {
                background-color: $color-black  !important;
                opacity: .4;
                border-color: rgba(0, 0, 0, .6);
            }
        }

       
    }

    &.btn-upload {
       
        font-size: $font-16;
        line-height: $font-26;
           span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }
    }

    &.btn-upload-transparent {
      
        font-size: $font-16;
        line-height: $font-26;
      
        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }
    }

    &.btn-resend {
      
        font-size: $font-16;
        line-height: $font-26;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &.resend-confirm,
        &:focus {
            background-color: $color-blue1;
            border: 1px solid $color-blue1;
            color: $color-white;
        }
    }

    &.btn-cancel {
        background-color: $color-white;
        border: 1px solid $color-lightgrey10;
        color: $color-lightgrey10;
        font-size: $font-16;
        line-height: $font-26;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &:focus {
            border: 2px solid $color-lightgrey10;
        }
    }

    &.btn-grey {
        background-color: $color-lightgrey24;
        border: 1px solid $color-lightgrey24;
        color: $color-lightgrey23;
        font-size: $font-16;
        line-height: $font-26;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &:focus {
            border: 2px solid $color-lightgrey24;
        }
    }

    &.btn-white-blue {
        background-color: $color-white;
        border: 1px solid $color-white;
        color: $color-blue1;
        font-size: $font-16;
        line-height: $font-26;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &:focus {
            border: 2px solid $color-white;
        }
    }

    &.btn-white-white {
        background-color: transparent;
        border: 1px solid $color-white;
        color: $color-white;
        font-size: $font-16;
        line-height: $font-26;
        border-radius: 5px;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &:focus {
            border: 1px solid $color-white;
        }
    }

    &.btn-white-blue2 {
        background-color: $color-white;
        border: 1px solid $color-blue1;
        color: $color-blue1;
        font-size: $font-16;
        line-height: $font-26;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &:focus {
            border: 2px solid $color-blue1;
        }
    }

    &.btn-white-grey {
        background-color: $color-white;
        border: 1px solid $color-lightgrey31;
        color: $color-lightgrey7;
        font-size: $font-16;
        line-height: $font-26;

        span {
            font-family: 'Inter', sans-serif;
    font-weight: 400;
        }

        &:focus {
            border: 2px solid $color-lightgrey31;
        }
    }

    &:disabled {
        opacity: 0.3;
    }
}

// areas of interest scss
.areas-main {
    .mat-list-item-content {
        background: $color-lightgrey13;
        border-radius: 25px;
        padding: 12px 20px !important;
        height: auto !important;
        line-height: 25px;
    }

    .mat-pseudo-checkbox {
        position: absolute;
        left: 16px;
        background: $color-white;
        border: 0px;
        border-radius: 50%;
        width: 20px;
        height: 20px;

        &.mat-pseudo-checkbox-checked {
            background: $color-blue2;

            &::after {
                top: 6.4px;
                left: 5.2px;
            }
        }
    }

    .mat-list-text {
        padding-left: 25px !important;
        padding-right: 0px !important;
        position: relative;
        top: 1px;
        font-size: $font-15;
        color: $color-blue2;
        line-height: $font-18;
    }

    .mat-list-item-content {
        margin: 0px 15px 15px 0px;
    }
}

// mat table scss
.mat-table {
    tr.mat-header-row {
        height: auto;

        .mat-header-cell {
            color: $color-lightgrey6;
            font-weight: normal;
            font-size: $font-14;
            padding: 8px 12px !important;
        }
    }

    tr.mat-row,
    tr.mat-footer-row {
        height: auto;

        .mat-cell,
        .mat-footer-cell {
            color: $color-lightgrey7;
            padding: 6px 12px !important;

            &.cdk-column-name {
                color: $color-black-80;
            }

            &.library-name span {
                position: relative;
                top: 3px;
                cursor: pointer;
            }
        }

        &:hover {
            background-color: $color-lightgrey18;
        }

        &.active-row {
            td {
                background-color: $color-lightgrey17;
            }
        }
    }

    &.library-list-view-table {

        .mat-footer-cell,
        .mat-header-cell,
        tr.mat-row .mat-cell {
            padding: 12px 12px !important;
        }
    }
}

.topic-feeds-table {
    thead {
        display: none;
    }

    &.mat-table tr.mat-row .mat-cell,
    &.mat-table tr.mat-row .mat-footer-cell,
    &.mat-table tr.mat-footer-row .mat-cell,
    &.mat-table tr.mat-footer-row .mat-footer-cell {
        padding: 12px 24px 12px 0px !important;

        &.mat-column-category,
        &.mat-column-title {
            color: $color-black;
        }

        &.mat-column-views,
        &.mat-column-time,
        &.mat-column-description {
            color: $color-lightgrey25;
        }

        &.mat-column-title {
            font-size: $font-15;
        }
    }
}

// mat select
.mat-select-grid {
    .mat-select-arrow {
        position: relative;
        top: 5px;
        right: -10px;
    }
}

// mat drawer global scss

// .mat-drawer-container {
//     .mat-drawer-backdrop.mat-drawer-shown {
//         background-color: transparent;
//     }
//     .mat-drawer {
//         top: 50px;
//         box-shadow: -2px 0px 10px 0px rgb(0 0 0 / 16%);
//     }
// }

// mat menu profile image
.profile-image-matMenu {
    input[id="file"] {
        display: none;
    }

    .remove-picture span {
        color: $color-red;
    }
}

// mat radio group
.user-images-list mat-radio-group {
    .mat-radio-button {
        padding-right: 20px;
        padding-bottom: 10px;
        width: 50%;

        .mat-radio-label-content {
            padding-left: 0px;
        }
    }

    label.mat-radio-label {
        position: relative;

        .mat-radio-container {
            position: absolute;
            top: 2px;
            left: 2px;
            z-index: 9;

            .mat-radio-outer-circle {
                background-color: $color-white;
                border-radius: 0px;
                display: none;
            }

            .mat-radio-inner-circle {
                background-image: url("../../assets/images/icons/ic-checkmark-blue.svg");
                background-repeat: no-repeat;
                background-size: 40px 60px;
                background-color: transparent;
                width: 50px;
                height: 45px;
                background-position: -1px -15px;
                position: relative;
                left: -8px;
                top: -7px;
            }
        }
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: $color-blue1;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color: $color-blue1;
    }

    // .avatar-img {
    //     position:relative;
    //     width:100%;
    //     height:100%;
    // }
    // .avatar-img img {
    //     width:100%;
    //     vertical-align:top;
    // }

    // .avatar-img:after, .avatar-img:before, .mat-radio-checked .avatar-img:after, .mat-radio-checked .avatar-img:before {
    //     position:absolute !important;
    //     opacity:0 !important;
    //     transition: all 0.5s !important;
    //     -webkit-transition: all 0.5s !important;
    // }
    // .avatar-img:after, .mat-radio-checked .avatar-img:after {
    //     content:'\A' !important;
    //     width:100%; height:100% !important;
    //     top:0; left:0 !important;
    //     background:rgba(255, 255, 255, 0.5) !important;
    // }
    // .imavatar-imgage:before, .mat-radio-checked:before {
    //     content: attr(data-content) !important;
    //     width:100% !important;
    //     color:#fff !important;
    //     z-index:1 !important;
    //     bottom:0 !important;
    //     text-align:center !important;
    //     box-sizing:border-box !important;
    //     -moz-box-sizing:border-box !important;
    // }
    // .avatar-img:hover:after, .avatar-img:hover:before, .mat-radio-checked .avatar-img:after, .mat-radio-checked .avatar-img:before {
    //     opacity:1  !important;
    // }
}

// mat tab group
.mat-tab-group {
    .mat-tab-label {
        height: auto;
        min-width: auto;
        padding: 15px 20px 10px 20px;

        &.mat-tab-label-active {
            
            opacity: 1;

            .mat-tab-label-content {
               
            }
        }
    }
}

// global connections buttons
.actions-row {
    flex-direction: row !important;

    button {
        padding: 3px 15px;
        border-radius: 5px;
        margin-left: 8px;
        margin-right: 8px;
    }
}

.manage-connections-box {
    .actions-row {
        width: 100%;

        & button {
            width: 100%;
            font-size: 13px;
            line-height: 36px;
        }

        button.more-button-icon {
            // position: absolute;
            // top: 24px;
            // right: -10px;
            // transform: rotate(90deg);

            .icon-ellipsis-horizontal {
                // height: 3.8px;
            }
        }

        button.btn-submit {
            // position: absolute;
            // bottom: 30px;
            // right: 19px;
            // /* min-width: 123px; */
            // left: 17px;
            // max-width: 100%;
            // //width: 220px;
            // align-items: center;
            // height: 100%;
            // max-height: 40px;
            // text-align: center;
        }

        button.mat-focus-indicator.btn-submit.accept_btn_new.mat-raised-button.mat-button-base.ng-star-inserted {
            // width: 100px;
        }
    }
}

.search-main {
    .actions-row button.more-button-icon {
        transform: rotate(90deg);
    }
}

.actions-column-profile {
    flex-direction: column !important;

    button {
        padding: 3px 15px;
        border-radius: 5px;
        margin-top: 6px;
        margin-bottom: 6px;

        &.btn-submit {
            background-color: $color-white;
            color: $color-blue1;
        }

        &.btn-upload {
            background-color: transparent;
            color: $color-white;
            border: 1px solid $color-white;
        }
    }
}

.share-autocomplete {
    .mat-form-field-infix {
        border-top: 10px;
        padding: 14px 12px 15px 10px;
    }

    .mat-form-field-label {
        top: 33px;
    }
}

.ngx-otp-input {
    width: 50px !important;
    height: 50px !important;
    font-size: $font-26  !important;
    line-height: $font-34;
}

// mac extra large screens
@media screen and (max-width: 2559px) and (min-width: 1920px) {
    .mac-lg-25-20 {
        max-width: 25% !important;
    }

    .mac-lg-20-20 {
        max-width: 20% !important;
    }
}

@media screen and (min-width: 2560px) {
    .mac-lg-25-20 {
        max-width: 20% !important;
    }

    .mac-lg-20-20 {
        max-width: 20% !important;
    }
}

.full-screen-dialog {
    max-width: 100vw !important;
    box-sizing: border-box;
    overflow: hidden;

    .mat-dialog-container {
        border-radius: 0 !important;
        width: 100vw;
        height: 100vh;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
}

.article-content-main {
    min-height: 100%;

    &.dark-theme {
        background-color: rgba(0, 0, 0, 1);

        .article-content,
        .mat-dialog-title,
        .close-text,
        .p-small-grey14 {
            color: $color-white;
        }

        pre {
            color: $color-lightgrey27;
        }

        .mat-stepper-horizontal,
        .mat-stepper-vertical {
            background-color: #1a1a1a;
            box-shadow: 0px 6px 18px #00000012;
            margin-top: 30px;
            /* color: #fff; */
        }

        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
            border-color: #f8f9fa !important;
        }

        .mat-radio-outer-circle {
            border-color: #ffffff !important;
        }

        .mat-horizontal-stepper-header::before,
        .mat-horizontal-stepper-header::after,
        .mat-stepper-horizontal-line {
            border-top-color: #f8f9fa;
        }

        button.btn-grey[_ngcontent-ytq-c242] {
            background-color: #212529 !important;
            border: 1px solid #F2F2F2;
            color: #f8f9fa;
            font-size: 16px;
            line-height: 34px;
        }

        .mat-step-text-label {
            text-overflow: ellipsis;
            overflow: hidden;
            color: #fff;
        }

        .article-content-main.dark-theme .mat-horizontal-stepper-content {
            outline: 0;
            color: #ffffff !important;
        }

        .article-content-main.dark-theme span.mat-radio-label-content {
            color: #f8f9fa;
        }

        span.mat-radio-label-content {
            color: #fff !important;
        }

        .mat-horizontal-stepper-content {
            outline: 0;
            color: #fff !important;
        }

        .mat-radio-inner-circle {
            background-color: #f8f9fa !important;
        }

        .library-article-full {
            p a {
                color: $color-white;
                border-bottom: 1px solid $color-white;

                &:hover {
                    color: $color-hover1;
                    border-bottom: 1px solid $color-hover1;
                }
            }

            a {
                color: $color-hover1;
            }

            blockquote p {
                color: $color-lightgrey38;
            }
        }

        .article-header {
            border-bottom: 1px solid #191919;
            background: #191919;
        }

        .article-header-end app-library-node-actions mat-icon {
            color: $color-white;
        }

        .hint-block label {
            color: black;
        }

        section.directionsec {
            color: black;
        }

        .p-20.buttons-section {
            color: black;
        }

        & app-questionnaire-run {
            background: #1a1a1a;

            & * {
                color: #fff !important;
            }
            & .gr-c1{ background:rgba(255,255,255,.05);
                & .red{     color: #dd5e59 !important;}
                & .green{ color: #62c282 !important;}
            
            }
            & .gr-c{ background: rgba(98, 194, 130, 1);}
            & .gr-w{     background: rgba(221, 94, 89, 1);}

            & mat-selection-list {
                background: #1a1a1a;

                & mat-list-option {
                    background: #1a1a1a !important;
                }
            }

            & .noborder .mat-tab-header {
                background: #1a1a1a;
                border-bottom: 1px solid rgba(255, 255, 255, .12) !important;
            }

            & .noborder .mat-tab-labels button {
                border: 1px solid rgba(255, 255, 255, .12) !important;
            }

            .option-key {
                background: #292626 !important;
            }
        }

    }

    &.light-theme {
        background-color: $color-white;

        .article-content,
        .mat-dialog-title,
        .close-text {
            color: $color-black;
        }

        .p-small-grey14 {
            color: $color-lightgrey14;
        }

        .article-header {
            border-bottom: 1px solid $color-lightgrey3;
        }
    }

    .gr-block .txt1 {
        color: black;
    }

    .gr-block .desctxt {
        color: black;
    }
}

app-library-file-viewer-dialog .mat-dialog-content {
    max-height: calc(100vh - 40px) !important;
}

app-library-file-viewer-dialog .mat-dialog-content iframe {
    height: 90vh !important;
}

.library-search {
    .mat-form-field-appearance-outline {
        .mat-form-field-outline {
            background: $color-lightgrey20;
            border-radius: 6px;
            color: transparent;
        }

        .mat-form-field-infix {
            padding: 7px 12px 7px 2px;
            border-top: 4px solid transparent;
        }

        .mat-form-field-flex {
            padding-right: 5px;
        }
    }
}

.add-new-space-library-picker {
    .library-picker-main .list-view-main {
        height: calc(100vh - 163px) !important;
        padding-bottom: 0px !important;
    }

    .libray-list-loading {
        width: 100%;
        height: calc(100vh - 165px) !important;
    }
}

.library-picker-main {
    .list-view-main {
        height: calc(100vh - 223px) !important;
        padding-bottom: 0px !important;
    }
}

.library-video iframe {
    width: 100%;
    height: 240px;
}

.article-center {
    .library-video-full {
        align-items: center;
        display: flex;
        justify-content: center;
        height: calc(100vh - 60px);

        iframe {
            height: 385px !important;
            width: 640px;
            width: 100%;
        }
    }

    .library-article-full {
        font-family: "Roboto", sans-serif;
        width: 100%;
        padding-bottom: 60px;

        p,
        img,
        li,
        span,
        div {
            margin-bottom: 12px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding-bottom: 20px;
            font-weight: 500;
            
        }

        h1 {
            font-size: $font-36;
            line-height: $font-48;
        }

        h2 {
            font-size: $font-30;
            line-height: $font-44;
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 0px auto;
            display: table;
            border-radius: 6px;
        }

        ul {
            margin-left: 40px;
            margin-top: 10px;
            padding-bottom: 20px;
        }

        p {
            font-size: $font-20;
            line-height: $font-30;
            letter-spacing: 0.02em;
            font-weight: normal;
            margin-bottom: 20px;

            a {
                color: $color-black;
                border-bottom: 1px solid $color-black;

                &:hover {
                    color: $color-blue2;
                    border-bottom: 1px solid $color-blue2;
                    text-decoration: none;
                }
            }
        }

        a {
            color: $color-blue1;
        }

        li {
            list-style-position: initial;
            font-size: $font-18;
            line-height: $font-26;
            letter-spacing: 0.02em;
            font-weight: normal;
            margin-bottom: 10px;
        }

        strong {
            
            font-weight: normal;
        }

        section:first-child h1:first-child {
            text-align: center;
            font-size: $font-40;
            line-height: $font-52;
        }

        blockquote {
            text-align: center;
            margin: 40px;
            padding: 30px;

            p {
                font-size: 26px;
                line-height: 42px;
                color: $color-lightgrey14;
            }
        }
    }
}

.article-full-width {
    height: 100%;
    overflow-y: auto;

    article {
        height: 100%;
        align-items: center;
        display: flex;
    }

    .article-center {
        width: 100% !important;
        height: calc(100% - 10px);
        overflow-y: auto;
    }

    .library-video-full {
        align-items: center;
        display: flex;
        justify-content: center;
        height: calc(100% - 45px);

        iframe {
            position: absolute;
            top: 0px;
            bottom: 0px;
            
            width: 100%;
            height: 100% !important;
            left: 0;
        }
    }

    .video-fullscreen {
        video{ position: absolute;
            top: 0px;
            bottom: 0px;
            
            width: 100%;
            height: 100% !important;
            left: 0;}
    }
}

.library-article {
    width: 100%;
    height: 70vh;
    padding-bottom: 60px;

    p,
    img,
    li,
    span,
    div {
        margin-bottom: 12px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding-bottom: 20px;
        font-weight: 500;
        
    }

    h1 {
        font-size: $font-36;
        line-height: $font-48;
    }

    h2 {
        font-size: $font-30;
        line-height: $font-44;
    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0px auto;
        display: table;
        border-radius: 6px;
    }

    ul {
        margin-left: 40px;
        margin-top: 10px;
        padding-bottom: 20px;
    }

    p {
        font-size: $font-20;
        line-height: $font-30;
        letter-spacing: 0.02em;
        font-weight: normal;
        margin-bottom: 20px;

        a {
            color: $color-black;
            border-bottom: 1px solid $color-black;

            &:hover {
                color: $color-blue2;
                border-bottom: 1px solid $color-blue2;
                text-decoration: none;
            }
        }
    }

    a {
        color: $color-blue1;
    }

    li {
        list-style-position: initial;
        font-size: $font-18;
        line-height: $font-26;
        letter-spacing: 0.02em;
        font-weight: normal;
        margin-bottom: 10px;
    }

    strong {
        
        font-weight: normal;
    }

    section:first-child h1:first-child {
        text-align: center;
        font-size: $font-40;
        line-height: $font-52;
    }

    blockquote {
        text-align: center;
        margin: 40px;
        padding: 30px;

        p {
            font-size: 26px;
            line-height: 42px;
            color: $color-lightgrey14;
        }
    }
}

.card-info-body .mat-tab-body {
    height: calc(100vh - 245px);
}

.show-loading {
    pointer-events: none;
}

.h-100 {
    height: 100%;
}

.gridster-item-content {
    height: 100%;

    .article-content {
        margin: 0px 10px;
    }
}

// profile image scss
.card-box .body-main .profile-img,
.comments-profile {

    img.user-img,
    .placeholder-name {
        width: 40px !important;
        height: 40px !important;
    }

    .placeholder-name h2 {
        font-size: 16px;
        line-height: 20px;
        position: relative;
        left: 1px;
        top: 0px;
    }
}

.comments-profile .profile-img {
    padding-right: 0px !important;
}

.user-auto-complete-option {
    app-profile-image {
        float: left;
        position: relative;
        top: 8px;
    }

    img.user-img,
    .placeholder-name {
        width: 32px !important;
        height: 32px !important;
    }

    .placeholder-name h2 {
        font-size: 14px;
        line-height: 20px;
        position: relative;
        left: 1px;
        top: 0px;
    }
}


// profile nav tabs
.profile-navtabs {
    .mat-tab-group {
        width: 100%;
    }

    .mat-tab-header {
        border-bottom: 0px;
        padding-left: 20px;
        padding-bottom: 20px;
    }

    .mat-tab-body {
        height: calc(100vh - 157px);
        background-color: $color-lightgrey40;
    }

    .mat-tab-label {
        padding: 8px 30px;
        border-radius: 22px;
        min-width: 120px;
        margin-right: 20px;
        background: #fff;
       
        font-size: 16px;

        &:last-child {
            right: 0;
            padding: 0;
            margin: 0;
            border: 0;

            & button {
                margin: 0 20px 0 0 !important;
            }
        }

        
    }

    .mat-ink-bar {
        background-color: transparent !important;
    }
}

.published-space.card-box.body-header {
    padding: 15px !important;
    height: 111px !important;
    border-radius: 10px 10px 0px 0px;
}

.button-black {

    background-color: $color-black  !important;
    border-color: $color-black  !important;
    color: #fff;
}


button.mat-focus-indicator.mat-menu-trigger.mat_more_vert.mat-icon-button.mat-button-base.ng-star-inserted {
    width: 35px !important;
    background-color: #ededed;
    padding-left: -10px;
    border-radius: 3px;
    margin-right: 10px;
    /* margin-left: 5px; */
}

.roundedBtn{ border-radius: 32px !important;}

app-user-profile-connection-actions.btn-accept-decline {
    margin-left: 125px !important;
}

.manage-connections-box button.mat-focus-indicator.btn-cancel.btn-tp.cancel_req.mat-raised-button.mat-button-base.ng-star-inserted {
    position: absolute !important;
    bottom: 29px;
    right: 0px;
    /* min-width: 123px; */
    left: 17px;
    max-width: 100%;
    width: 220px !important;
    align-items: center;
    height: 100%;
    max-height: 40px;
    text-align: center;
}

.invite-people-sidebar {
    .mat-form-field-flex {
      
        
        padding: 11px 0px 1px 19px;
        min-height: 80px;
        margin: 15px 0px 0px 0px;

        .mat-form-field-infix {
            padding: 0px;
            border: 0px;
            font-size: $font-14;
            line-height: $font-22;
            position: relative;
            top: -11px;
        }
    }

    .mat-chip-list-wrapper input.mat-input-element,
    .mat-chip-list-wrapper .mat-standard-chip {
        // margin: 5px 0px 17px 0px;
        margin: 22px 0px 0px 0px;
    }

    .mat-chip-list-wrapper {
       
        margin: 0px;
    }

    .mat-form-field-label {
        padding-top: 15px;
    }

    &.mat-form-field-appearance-legacy {

        .mat-form-field-underline,
        .mat-form-field-ripple {
            height: 0px;
        }
    }

    .mat-chip {
        line-height: $font-18;
        font-size: $font-14;
        padding: 8px 15px;
        height: auto;
    }

    .mat-chip.mat-standard-chip {
        background-color: $color-blue6;
        color: $color-blue2;
    }

    .mat-standard-chip:hover::after,
    .mat-standard-chip:focus::after {
        opacity: 0;
    }

    .mat-chip.mat-standard-chip .mat-chip-remove {
        opacity: 1;
    }

    .mat-standard-chip .mat-chip-remove,
    .mat-standard-chip .mat-chip-trailing-icon {
        margin-left: 12px;
    }
}

.app-spaces .fl_center {
    margin-right: 137px;
}

/* new styles remove  multiple scroll */
.card-left-part {
    & .spaces-cards.byndr_space {
        height: calc(100vh - 59px) !important;

        & .card-main {
            max-height: calc(100vh - 80px);
        }

    }
}

.spaces-main {

    & .my_space,
    & .learning_path_space {
        & .card-main {
            max-height: calc(100vh - 143px);
        }

    }
}

app-my-profile {
    .profile-navtabs {
        & .spaces-cards {
            height: calc(100vh - 157px) !important;
        }

        & .card-main {

            max-height: calc(100vh - 157px);
        }
    }
}

/* new styles remove  multiple scroll */

.custom_select {

    & .mat-form-field-outline {
        color: #7A7A7A !important;
        background: #f7f7f7;
    }


    & .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    & .mat-form-field-infix {
        border-top: 0;
        padding: 0 !important;
        border: 0;
        margin: 0;
        min-height: 46px;
        padding-top: 16px !important;
    }

    & .mat-select-arrow-wrapper {
        position: relative;
        top: 3px;
    }

    & .mat-select-arrow {
        width: 10px;
        height: 10px;
        border-left: 2px solid;
        border-right: 0;
        border-top: 2px solid;
        margin: 0 4px;
        transform: rotate(-132deg);
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $color-lightgrey12;
width: 0;
   visibility: hidden;
   display: none;
}

::-webkit-scrollbar {
    width: 0px;
    background-color: $color-lightgrey12;
   visibility: hidden;
   display: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    opacity: 0; 
    visibility: hidden;
    display: none;
}

.disabled-card {
    & * {
        cursor: default !important;
    }

    &.list-box {
        background: #F2F2F2 !important;

        & button {
            background: none !important;
        }
    }

    & .card-box {
        background: #F2F2F2 !important;
        border-color: #DDDDDD !important;

        &.selected {
            border-color: #2D68F8 !important;
        }

        & .settingsbar button {
            background: none;
        }

        & * {
            filter: grayscale(4);
        }

        & .body-header {
            h4 {
                color: rgba(0, 0, 0, .5) !important;
                background: none !important;
            }
        }

        & .h6-15 {
            color: rgba(0, 0, 0, .5) !important;
        }

        & .description-text {
            color: rgba(0, 0, 0, .7) !important;
            height: auto !important;
        }

        & .views_section {
            opacity: .5;
        }

        & .views_count {
            opacity: .4;
        }

        & app-learning-space-action-bar {
            opacity: .4;
            pointer-events: none;
        }
    }
}

.customModal {
    & .mat-dialog-actions {
        margin-bottom: 0;
        padding: 0px 20px;
    }

    & .mat-dialog-content {
        margin: 0;
        padding: 0;
    }

    & .mat-dialog-title {
        padding: 10px 20px;
        margin: 0;
        border-bottom: 1px solid #ccc;
        font-family: 'Inter', sans-serif;
    font-weight: 500;
        font-size: 22px;
        position: relative;

        & .mat-icon-button {
            position: absolute;
            top: 6px;
            right: 10px;
        }
    }

    & .spaces-cards {
        height: calc(65vh) !important;
    }
}

.centeredModal {

    & .mat-dialog-title {
        text-align: center;
        font-size: 30px;
        border: 0;
        padding-top: 30px;

        & .dialog-slogan {
            font-size: 20px;
            color: rgba(0, 0, 0, .8);
            display: block;
            font-family: 'Inter', sans-serif;
    font-weight: 400;
            letter-spacing: -1px;
        }

    }

    & .mat-dialog-actions {
        padding: 20px 20px
    }

    & .mat-dialog-content {
        max-height: calc(100vh - 300px);
    }

    & .spaces-cards {
        height: calc(100vh - 300px) !important;
        background: #fff !important;
    }

}

.right_slide_popup {
    & .mat-dialog-content {
        max-height: calc(100vh - 105px);
        height: calc(100vh - 105px);
    }

    & .mat-dialog-container {
        border-radius: 0 !important;
    }

    & .spaces-cards {
        height: calc(100vh - 105px) !important;
    }
}


@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

.animate__animated {

    animation-duration: .5s;

    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.animate__slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
}

.more-horiz-menu button {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-weight: 500;

    &.mat-menu-item .icon {
        width: 14px !important;
        height: 14px !important;
    }
}

.intersetlist {
    & .mat-menu-item {
        font-size: 13px;
        display: block;
        width: 100%;
        height: auto;
        line-height: 16px;
        padding: 5px 10px;
    }
}

.f-semibold {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.f-medium {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.f-regular {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.f-22 {
    font-size: $f-22;
}

.f-24 {
    font-size: $f-24;
    letter-spacing: -0.38px;
}

.f-26 {
    font-size: $f-26;
}

.f-28 {
    font-size: $f-28;
}

.f-30 {
    font-size: $f-30;
}

.f-34 {
    font-size: $f-34;
}

.f-36 {
    font-size: $f-36;
    letter-spacing: -0.58px;
}

.f-38 {
    font-size: $f-38;
}

.f-40 {
    font-size: $f-40;
}

.f-42 {
    font-size: $f-42;
}

.f-44 {
    font-size: $f-44;
}

.f-48 {
    font-size: $f-48;
}

.f-52 {
    font-size: $f-52;
}

.learningpathModal {
    & .learningicon .icon {
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
    }

    & .mat-dialog-title {
        & .learningicon .icon {
            width: 22px;
            height: 22px;
            background-size: 22px 22px;
        }
    }

    & .searchList .list-box {
        align-items: start !important;
    }

    & .mat-dialog-content {
        max-height: calc(100vh - 200px) !important;
    }

    .spaces-cards {

        height: calc(100vh - 225px) !important;
    }

}

.mat-drawer-container {
    background: #f7f7f7 !important;
}

.tabsWrap {
    list-style: none;
    margin: 0;

    & li {
        list-style: none;
        margin: 0;

        & a {
            border-radius: 30px;
           
            padding: 7px 30px;
            display: inline-block;
            

            &:hover,
            &.active {
                text-decoration: none;
              
                
            }
        }
    }
}

.inner_title {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.results-body {
    & .spaces-cards {
        height: calc(100vh - 185px) !important;
    }

    & .list-view-main {
        height: calc(100vh - 186px) !important;
    }

    & .library-header {
        display: none !important;
    }
}

.results-data {
    &.loading-results {
        & .spaces-cards {
            height: calc(100vh - 230px) !important;
        }

    }
}

.menuiconWrap {
    .mat-menu-item .mat-icon {
        margin-right: 0;
    }
}


@media screen and (max-width: 1023px) {
    .left-sidebar-main {
        width: 100% !important;
    }

    .mobile-flex-column-reverse {
        flex-direction: column-reverse !important;
    }

    .home-body {
        height: calc(100vh - 102px) !important;

    }

    .card-right-part {
        display: none !important;
    }

    .card-left-part {
        max-width: 100% !important;
    }

    .home-icon > a {
        position: fixed;
        top: 17px;
        left: 10px
    }

    .header-main .header-left .title-main {
        min-width: 50px !important;
    }

    .ic-invite {
        display: none !important;
    }

    .right-view-main {
        width: 100% !important;
    }

    .card-left-part .spaces-cards.byndr_space {
        height: calc(100vh - 103px) !important;
    }

    .card-left-part .spaces-cards.byndr_space .card-main {
        max-height: calc(100vh - 104px);
    }

    .left-sidebar {
        width: 100%;
        padding: 0 !important;
        justify-content: space-between;
        flex-direction: row !important;

        & .left-icons {
            display: flex;
            justify-content: space-around;
            flex: 60%
        }

        & .profile-icon {
            position: static !important;
            display: flex;
            justify-content: space-around;
            padding-top: 10px;
            flex: 40%
        }
    }

    .header-main .search-bar input {
        width: 100% !important;
    }

    .mobilehide {
        display: none !important;
    }

    .grid-view-main {
        height: calc(100vh - 188px) !important;
    }

    .gridview_library>div {
        max-width: 100% !important;
        flex: 100% !important;

        & .single-line {
            white-space: break-spaces !important;
        }
    }

    .header-list {
        padding-right: 0 !important;
    }

    .addBtn.mat-raised-button {
        min-width: 42px;
        line-height: 27px;
        padding: 0 8px;

        & .mat-button-wrapper {
            font-size: 0;

            &:after {
                content: '+';
                font-size: 27px;
                top: 5px;
                position: relative;
            }
        }
    }

    .pagetabswrap {
        & li a {
            padding: 3px 5px;
            text-align: center;
            font-size: 12px;
            border-radius: 8px;

            & span {
                &:first-child {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 62px;
                    float: left
                }

                &:last-child {
                    display: inline-block;
                }
            }
        }
    }

    .header-list ul li {
        padding: 0 3px !important
    }

    .tabsWrap li {
        margin: 0 5px !important;
    }

    .signin-security-main {
        max-width: 90%;

        & .box-row {
            flex-direction: column !important;
            align-items: start !important;
        }
    }

    .tabsWrap li a {
        padding: 7px 15px;
        font-size: 12px;
    }

    .spaces-main .my_space .card-main {
        max-height: calc(100vh - 167px);
    }

    .spaces-main .learning_path_space .card-main {
        max-height: calc(100vh - 229px);
    }

    .inner_title {

        white-space: nowrap;
        font-size: 16px;
        text-overflow: ellipsis;
        max-width: 80%;
        overflow: hidden;
    }

    .lerning-display {
        display: none;
    }

    mat-icon.mat-icon.notranslate.mat-info.material-icons.mat-icon-no-color {
        margin-right: 5px !important;
    }

    .create-lsp-header {
        padding-left: 5px !important;
    }

    app-header {
        height: 60px !important
    }

    .ic-lsp-grey .icon,
    app-left-sidebar .user-img-below {
        width: 24px !important;
        height: 24px !important;
    }

    .settingsNav {
        margin: 0 !important;
    }

    app-left-sidebar {
        box-shadow: 0px -1px 4px #00000012;
        ;
    }


    .present-path {
        min-width: 30px !important;
        margin-right: 5px !important;

        & img {
            width: 20px !important;
        }
    }

    button.mat-focus-indicator.present-space.mat-raised-button.mat-button-base {
        margin-right: 0 !important;
        min-width: 26px !important;
    }

    .nxt-btn,
    .prev-btn {
        & .mat-button-wrapper {
            font-size: 0;
        }
    }

    gridster {

        height: calc(100vh - 216px) !important;

        &.gridster-with-bottom-bar {
            height: calc(100vh - 274px) !important;
        }
    }

    .lsp-title {
        font-size: 14px !important;
    }

    .lsp-path-count-subtitle {
        font-size: 12px !important;
    }

    app-left-sidebar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: #fff;
    }

    app-my-profile {
        & .profile-navtabs {
            & .card-main {
                max-height: initial !important;
            }

            & .spaces-cards {
                height: auto !important;
            }

            & .mat-tab-body {
                height: auto !important;
            }
        }


        .profile-navtabs .mat-tab-header {
            position: sticky;
            top: 0px;
            z-index: 9999;
            background: #fff;
        }

    }

    .f-36 {
        font-size: 14px;
    }

    .f-24 {
        font-size: 12px;
    }

    .box-wrapper h4,
    .box-wrapper h5 {
        font-size: 18px !important;
    }


}

@media screen and (min-width: 996px) {
    .profile-navtabs .mat-tab-label:last-child {
        position: absolute;
        right: 0;
    }
}




app-spaces-list app-learning-space-action-bar {
    width: 100%;
}

app-library-file-viewer-dialog {
    & .learning-flex .flex-container-icon {
        margin: 0;
    }
}

.full-screen-dialog .mat-dialog-container {
    transition: none !important;
    transform: none !important
}

app-library-file-viewer-dialog app-learning-space-action-bar {
    & .icons-row {
        & button {
            height: 30px;
            padding-left: 8px !important;
            padding-right: 8px !important;
            width: auto !important;
        }
    }
}

.folder-menu { align-items: center;
    justify-content: center;
    display: flex;}

    .library-article-full iframe{ margin: 0 auto;}

    app-my-profile{  .profile-img { margin-right: 0; padding-right: 0;}
}

.menuOpen{
    & .spaces-cards.user_profile_space .card-main .space-card.pr-20 {
    max-width: 33.33% !important;
    flex: 1 1 33.33% !important;
}
& .connection_res.connection-row
{
    width:33.33% !important
}
}


.mat-form-field-appearance-outline .mat-form-field-outline{ background:#f7f7f7}
// .mat-form-field-appearance-outline .mat-form-field-outline-start,
//  .mat-form-field-appearance-outline .mat-form-field-outline-end
// {
//     border:0 !important
// }

// .mat-form-field-appearance-outline.mat-form-field-invalid
//  .mat-form-field-outline-thick {
//     color: #f44336 !important;
// }

// .mat-form-field-appearance-outline
//  .mat-form-field-outline-thick,
 
// .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
// .mat-form-field-appearance-outline.ng-valid .mat-form-field-outline {
//     color: #f7f7f7 !important;
    
// }

.mat-form-field-appearance-fill.mat-focused .mat-form-field-label{ color: #000 !important;}

.mat-form-field-appearance-fill .mat-form-field-underline,
.mat-form-field-appearance-fill.mat-focused .mat-form-field-underline,
.mat-form-field-appearance-fill:hover .mat-form-field-underline,
.mat-form-field-appearance-fill.ng-valid .mat-form-field-underline

{
    &:before{
    background-color: #f7f7f7 !important;
    }


    & .mat-form-field-ripple{ display: none;}
}

.mat-form-field.mat-form-field-invalid  .mat-form-field-ripple{ display: block !important;}


.mat-form-field-should-float{ 
    & .mat-form-field-flex{ background:#fff !important; border: 1px solid #cecece; border-radius: 5px !important; }



&.mat-form-field-appearance-fill .mat-form-field-underline,
&.mat-form-field-appearance-fill.mat-focused .mat-form-field-underline,
&.mat-form-field-appearance-fill:hover .mat-form-field-underline,
&.mat-form-field-appearance-fill.ng-valid .mat-form-field-underline

{
    &:before{
    background-color: #f7f7f7 !important;
    display: none !important;
    }


    
}




}

app-user-profile-meta-row {

    & .profile-img{ padding-right: 10px;}
}


.requestscreen {

    & .search-connections-profile h6.h6-15{ width: auto;}

    & .search-connections-profile .user-img{ width:28px !important; height: 28px !important;}
    & .profile-img{ padding-right: 10px;}
}

.topheader{ & .newlink{ position: absolute; top:11px; left: 10px;}}
app-library-info 
{
    
    & .mat-tab-group .mat-tab-label.mat-tab-label-active{ border:0 !important}
& .mat-ink-bar{ display: none;}

& .mat-tab-label{ border-radius: 20px;
    
    padding: 0 20px !important;
    height: 36px !important; background:#f7f7f7; color:#000}
    & .mat-tab-header{ padding-bottom: 15px;}

& app-questionnaire-run{
    & .mat-tab-label{ border-radius: 4px; margin-right: 10px; padding: 0 !important; & .mat-button-wrapper{ color: #000; font-size: 20px;} }
    & .mat-tab-header{ border-bottom: 0;}
}

}

.mat-menu-item{ padding: 0 20px 0 30px !important; color: #000 !important;}
.mat-menu-panel{ border-radius: 10px !important; box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;}
.mat-menu-item {
.p-small-black15 {
    color: #000000;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
}
}
.mat-menu-content:not(:empty) {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.actionssec .favorite_icons{ display: none !important;}

.cdk-overlay-dark-backdrop {
    background: rgba(0,0,0,.62) !important;
}

app-questionnaire-create {
    & .mat-tab-group .mat-tab-label.mat-tab-label-active{ border:0 !important}
    & mat-ink-bar{ display: none;}
    & .mat-tab-header { border:0}
  &  .mat-tab-labels button {
        min-width: 50px;
        line-height: 50px;
    }

   & .mat-tab-group .mat-tab-label {
        padding: 10px 20px 10px 0;
    }
}


mat-dialog-container{
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $color-lightgrey12;
width: 10px;
opacity: 1; 
visibility: visible;
display: block;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: $color-lightgrey12;
    opacity: 1; 
    visibility: visible;
    display: block;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    opacity: 1; 
    visibility: visible;
    display: block;
}
}
.mat-tab-label{ opacity: 1 !important;}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(3 119 255 / 30%);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: #0377FF;
}
.update-email-main{ padding: 0 30px;
    & .mat-stepper-horizontal, .mat-stepper-vertical{ box-shadow: none;}}

    app-library-file-viewer-dialog  app-questionnaire-run {

        & .mat-tab-group .mat-tab-label{ padding: 0 !important;
            height: 36px !important;
            background: #f7f7f7;
            border-radius: 4px;
    margin-right: 10px;

   &  .mat-button-wrapper {
        color: #000;
        font-size: 20px;
    }

        }
       & .mat-ink-bar {
            display: none;
        }
        .mat-tab-header {
            border-bottom: 0px solid rgba(0,0,0,.12); margin-bottom: 20px;
        }
    }
.requestscreen{
    h2{ font-size: 20px;
        font-weight: 600;
        margin: 10px 0;}
        p{
            font-size: 12px;
            padding-right: 35px;
            margin: 0 0 10px;
        }
    }

    .left-profile{ margin-top: 20px;}

.left-profile-inner {
    .accept-decline-btn { justify-content: center;}
    .accept-decline-btn > div button.btn-primary{ background:#fff !important; color: #000 !important; border:1px solid transparent !important }
    .accept-decline-btn > div button.btn-cancel{ background:none !important; color: #fff !important; border:1px solid #fff !important;}
}

.learning-space-detail .actionssec{ display: none !important;}

.learning-space-detail {
    & app-questionnaire-run{ padding-top:0}
    
    & .favIcon{ pointer-events: none;}

& .library-search .mat-form-field-appearance-outline .mat-form-field-infix{

    padding: 15px 12px 15px 2px;
    & .ma-icon{ top:12px}
}}

.connections-requests-list  app-user-profile-meta-row .user-row  > .pl-20{ padding-left: 0 !important;}
@media screen and (max-width: 1023px) {

       .closesidebar .left-icons a {
        padding: 2px 7px 2px 5px !important;
    }
    .profile-sec {
        margin: 0px 0 0 0 !important;
    }
    .closesidebar .home-icon {
        padding: 2px 2px !important;
    }
}
.hometabs{
& .mat-ink-bar{ display: none !important;}
& .mat-tab-header{ border:0 !important}
& .mat-tab-group .mat-tab-label.mat-tab-label-active{ border: 0 !important;}
}

app-home{
.mat-tab-group .mat-tab-label{ padding: 15px 5px 10px 5px;}}